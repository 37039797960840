import { useTranslation } from 'react-i18next'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { DateFilterType } from './DateFilterType'

interface FilterProps {
  text: string
  type: DateFilterType
  activeFilter: DateFilterType
  selected: (type: DateFilterType) => void
}

const Filter = ({ text, type, activeFilter, selected }: FilterProps) => {
  if (activeFilter === type) {
    return <ActiveFilter text={text} selected={() => selected(type)} />
  } else {
    return <InactiveFilter text={text} selected={() => selected(type)} />
  }
}

interface ActiveInactiveFilterProps {
  text: string
  selected: () => void
}

const ActiveFilter = ({ text, selected }: ActiveInactiveFilterProps) => {
  return (
    <button
      className="mx-1 min-w-fit rounded-3xl border border-primary bg-primary px-1 py-2.5 text-xs lowercase text-white"
      onClick={selected}
    >
      {text}
    </button>
  )
}

const InactiveFilter = ({ text, selected }: ActiveInactiveFilterProps) => {
  return (
    <button
      className="mx-1 min-w-fit rounded-3xl border border-black px-1 py-2.5 text-xs lowercase"
      onClick={selected}
    >
      {text}
    </button>
  )
}

interface DateFilterProps {
  filterSelected: (type: DateFilterType) => void
  currentFilter: DateFilterType
}

const DateFilter = ({ filterSelected, currentFilter }: DateFilterProps) => {
  const { t } = useTranslation()
  const { screenSize } = useWindowDimensions()

  function selected(type: DateFilterType) {
    filterSelected(type)
  }

  return (
    <div className="mt-2 grid w-full grid-cols-3 px-0 sm:grid-cols-4 sm:px-2 md:mt-0">
      <Filter
        text={t('calendar.dateFilter.thisWeek')}
        type={DateFilterType.ThisWeek}
        selected={selected}
        activeFilter={currentFilter}
      />
      <Filter
        text={t('calendar.dateFilter.nextWeek')}
        type={DateFilterType.NextWeek}
        selected={selected}
        activeFilter={currentFilter}
      />
      {screenSize !== 'wix' && (
        <Filter
          text={t('calendar.dateFilter.thisMonth')}
          type={DateFilterType.ThisMonth}
          selected={selected}
          activeFilter={currentFilter}
        />
      )}
      <Filter
        text={t('calendar.dateFilter.nextMonth')}
        type={DateFilterType.NextMonth}
        selected={selected}
        activeFilter={currentFilter}
      />
    </div>
  )
}

export default DateFilter
