import { generatePath } from 'react-router-dom'
import { Event } from 'generated/graphql'

export const SCHEDULE_PATH = '/schedule'
export const EVENT_PATH = '/event/:shareableId'
export const CREDITS_PATH = '/credits'
export const MEMBERSHIPS_PATH = '/memberships'
export const MANAGE_MEMBERSHIP_PATH = '/manageMembership'
export const CART_PATH = '/cart'
export const CHECKOUT_PATH = '/checkout'
export const IFRAME_CHECKOUT_PATH = '/iframeCheckout'
export const CREDITS_CHECKOUT_PATH = '/creditsCheckout'
export const BOOKING_CONFIRMATION_PATH = '/bookingConfirmation'
export const CREDITS_CONFIRMATION_PATH = '/creditsConfirmation'
export const MEMBERSHIP_CHECKOUT_PATH = '/membershipCheckout'
export const MEMBERSHIP_CONFIRMATION_PATH = '/membershipConfirmation'
export const LOGIN_PATH = '/login'
export const LOGOUT_PATH = '/logout'
export const LOGIN_UNAVAILABLE_PATH = '/loginUnavailable'
export const AUTH_CALLBACK_PATH = '/authCallback'
export const MANAGE_BOOKINGS_PATH = '/bookings'

export const BOOKING_CHECKOUT_SUCCESS_PATH = '/booking-checkout-success'
export const BOOKING_CHECKOUT_CANCEL_PATH = '/booking-checkout-cancel'
export const IFRAME_CHECKOUT_RETURN_PATH = '/iframe-checkout-return'
export const CREDITS_CHECKOUT_SUCCESS_PATH = '/credits-checkout-success'
export const CREDITS_CHECKOUT_CANCEL_PATH = '/credits-checkout-cancel'
export const MEMBERSHIP_CHECKOUT_SUCCESS_PATH = '/membership-checkout-success'
export const MEMBERSHIP_CHECKOUT_CANCEL_PATH = '/membership-checkout-cancel'

export const generateEventPath = (event: Event) => {
  return generatePath(EVENT_PATH, {
    shareableId: event.shareableId.shareableId,
  })
}
