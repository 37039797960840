import { Money, Iso4217 } from 'generated/graphql'

export function formatMoney(price: Money | 0, locale: string = 'en') {
  return price === 0
    ? '0.00'
    : new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: price.currency.isoCode,
      }).format(price.amount)
}

export function zeroMoney(currencyCode: string): Money {
  const lowercaseCode = currencyCode.toLowerCase()

  return {
    amount: 0,
    currency: {
      isoCode:
        Iso4217[
          (lowercaseCode.charAt(0).toUpperCase() +
            lowercaseCode.slice(1)) as keyof typeof Iso4217
        ],
    },
  }
}
