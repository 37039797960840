import { gql } from '@apollo/client'

// Queries
export const CHECKOUT_SESSION_STATUS_QUERY = gql`
  query CheckoutSessionStatus($checkoutSessionId: String!) {
    checkoutSessionStatus(checkoutSessionId: $checkoutSessionId) {
      id
      status
      paymentStatus
      orderType
      orderReference
    }
  }
`

// Mutations
export const CREATE_BOOKING_ORDER_MUTATION = gql`
  mutation CreateBookingOrder(
    $userId: ID!
    $events: [EventBookingInput!]!
    $checkoutUrls: CheckoutUrlsInput!
    $uiMode: CheckoutUiMode
    $trialPassRedemptionCode: String
  ) {
    createBookingOrder(
      input: {
        userId: $userId
        events: $events
        checkoutUrls: $checkoutUrls
        uiMode: $uiMode
        trialPassRedemptionCode: $trialPassRedemptionCode
      }
    ) {
      bookingOrder {
        id
        reference
        status
        user {
          ...UserParams
        }
      }
      paymentRedirectUrl
      paymentInstructions
      stripeClientSecret
      stripeAccountId
    }
  }
`

export const CANCEL_BOOKING_ORDER_PAYMENT_MUTATION = gql`
  mutation cancelBookingOrderPayment($orderId: ID!) {
    cancelBookingOrderPayment(input: { orderId: $orderId }) {
      status
    }
  }
`
