import { ReactNode } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import {
  SCHEDULE_PATH,
  EVENT_PATH,
  CREDITS_PATH,
  CREDITS_CHECKOUT_PATH,
  CREDITS_CHECKOUT_SUCCESS_PATH,
  CREDITS_CHECKOUT_CANCEL_PATH,
  CREDITS_CONFIRMATION_PATH,
  MEMBERSHIPS_PATH,
  MANAGE_MEMBERSHIP_PATH,
  MEMBERSHIP_CHECKOUT_PATH,
  MEMBERSHIP_CHECKOUT_SUCCESS_PATH,
  MEMBERSHIP_CHECKOUT_CANCEL_PATH,
  MEMBERSHIP_CONFIRMATION_PATH,
  CART_PATH,
  CHECKOUT_PATH,
  IFRAME_CHECKOUT_PATH,
  IFRAME_CHECKOUT_RETURN_PATH,
  BOOKING_CHECKOUT_SUCCESS_PATH,
  BOOKING_CHECKOUT_CANCEL_PATH,
  BOOKING_CONFIRMATION_PATH,
  LOGIN_PATH,
  LOGOUT_PATH,
  LOGIN_UNAVAILABLE_PATH,
  AUTH_CALLBACK_PATH,
  MANAGE_BOOKINGS_PATH,
} from 'Routes'
import { useLogin } from 'login/use-login'
import Bookings from 'pages/bookings/Bookings'
import Cart from 'pages/checkout/Cart'
import Checkout from 'pages/checkout/Checkout'
import BookingCheckoutCancel from 'pages/checkout/CheckoutCancel'
import BookingCheckoutSuccess from 'pages/checkout/CheckoutSuccess'
import BookingConfirmation from 'pages/checkout/Confirmation'
import CreditsCheckout from 'pages/credits/Checkout'
import CreditsCheckoutCancel from 'pages/credits/CheckoutCancel'
import CreditsCheckoutSuccess from 'pages/credits/CheckoutSuccess'
import CreditsConfirmation from 'pages/credits/Confirmation'
import Credits from 'pages/credits/Credits'
import AuthCallback from 'pages/login/AuthCallback'
import Login from 'pages/login/Login'
import LoginUnavailable from 'pages/login/LoginUnavailable'
import Logout from 'pages/login/Logout'
import MembershipCheckout from 'pages/memberships/Checkout'
import MembershipCheckoutCancel from 'pages/memberships/CheckoutCancel'
import MembershipCheckoutSuccess from 'pages/memberships/CheckoutSuccess'
import MembershipConfirmation from 'pages/memberships/Confirmation'
import ManageMembership from 'pages/memberships/ManageMembership'
import Memberships from 'pages/memberships/Memberships'
import EventDetails from 'pages/schedule/EventDetails'
import Schedule from 'pages/schedule/Schedule'
import IFrameCheckout from 'shared/pages/IFrameCheckout'
import IFrameCheckoutReturn from 'shared/pages/IFrameCheckoutReturn'

export const ProtectedRoute = ({ children }: { children: ReactNode }) => {
  const { user } = useLogin()

  if (!user) {
    return <Navigate to="/" />
  }

  return children
}

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Schedule />} />
        <Route path={SCHEDULE_PATH} element={<Schedule />} />
        <Route path={EVENT_PATH} element={<EventDetails />} />
        <Route path={CART_PATH} element={<Cart />} />
        <Route path={CHECKOUT_PATH} element={<Checkout />} />
        <Route path={IFRAME_CHECKOUT_PATH} element={<IFrameCheckout />} />
        <Route
          path={IFRAME_CHECKOUT_RETURN_PATH}
          element={<IFrameCheckoutReturn />}
        />
        <Route
          path={BOOKING_CHECKOUT_SUCCESS_PATH}
          element={<BookingCheckoutSuccess />}
        />
        <Route
          path={BOOKING_CHECKOUT_CANCEL_PATH}
          element={<BookingCheckoutCancel />}
        />
        <Route
          path={BOOKING_CONFIRMATION_PATH}
          element={<BookingConfirmation />}
        />
        <Route
          path={CREDITS_PATH}
          element={
            <ProtectedRoute>
              <Credits />
            </ProtectedRoute>
          }
        />
        <Route
          path={CREDITS_CHECKOUT_PATH}
          element={
            <ProtectedRoute>
              <CreditsCheckout />
            </ProtectedRoute>
          }
        />
        <Route
          path={CREDITS_CHECKOUT_SUCCESS_PATH}
          element={<CreditsCheckoutSuccess />}
        />
        <Route
          path={CREDITS_CONFIRMATION_PATH}
          element={<CreditsConfirmation />}
        />
        <Route
          path={CREDITS_CHECKOUT_CANCEL_PATH}
          element={<CreditsCheckoutCancel />}
        />
        <Route
          path={MEMBERSHIPS_PATH}
          element={
            <ProtectedRoute>
              <Memberships />
            </ProtectedRoute>
          }
        />
        <Route path={MANAGE_MEMBERSHIP_PATH} element={<ManageMembership />} />
        <Route
          path={MEMBERSHIP_CHECKOUT_PATH}
          element={
            <ProtectedRoute>
              <MembershipCheckout />
            </ProtectedRoute>
          }
        />
        <Route
          path={MEMBERSHIP_CHECKOUT_SUCCESS_PATH}
          element={<MembershipCheckoutSuccess />}
        />
        <Route
          path={MEMBERSHIP_CONFIRMATION_PATH}
          element={<MembershipConfirmation />}
        />
        <Route
          path={MEMBERSHIP_CHECKOUT_CANCEL_PATH}
          element={<MembershipCheckoutCancel />}
        />
        <Route path={LOGIN_PATH} element={<Login />} />
        <Route path={LOGOUT_PATH} element={<Logout />} />
        <Route path={LOGIN_UNAVAILABLE_PATH} element={<LoginUnavailable />} />
        <Route path={AUTH_CALLBACK_PATH} element={<AuthCallback />} />
        <Route path={MANAGE_BOOKINGS_PATH} element={<Bookings />} />
        <Route path="*" element={<Schedule />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
