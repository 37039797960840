import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { LOGIN_PATH } from 'Routes'
import { useBusiness } from 'business/use-business'
import { businessLogoUrl } from 'lib/business'
import CloseIcon from 'shared/components/CloseIcon'

const loginLink = () => {
  return `${window.location.protocol}//${window.location.hostname}${LOGIN_PATH}`
}

const LoginUnavailable = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const shareableLinkRef = useRef<HTMLDivElement>(null)
  const { business } = useBusiness()
  const [shareableLinkCopied, setShareableLinkCopied] = useState(false)
  const [shareableLinkCopyFailed, setShareableLinkCopyFailed] = useState(false)

  useEffect(() => {
    if (shareableLinkCopyFailed && shareableLinkRef.current) {
      const range = document.createRange()
      range.selectNodeContents(shareableLinkRef.current)

      const sel = window.getSelection()!
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }, [shareableLinkCopyFailed, shareableLinkRef])

  const copyLoginLinkToClipboard = () => {
    navigator.clipboard.writeText(loginLink()).then(
      () => {
        setShareableLinkCopyFailed(false)
        setShareableLinkCopied(true)
      },
      () => {
        setShareableLinkCopyFailed(true)
      }
    )
  }

  return (
    <div className="relative mx-auto px-8 pb-4 pt-8 text-center md:my-20 md:max-w-lg md:rounded-3xl md:bg-white md:px-8 md:py-6">
      <CloseIcon onClick={() => navigate(LOGIN_PATH, { replace: true })} />

      {business && business.logo && (
        <img
          src={businessLogoUrl(business)}
          alt={business.name}
          className="inline-block h-12 md:hidden"
        />
      )}

      <div className="text-xl">{t('login.unavailable')}</div>

      <div className="flex flex-col">
        <button
          onClick={copyLoginLinkToClipboard}
          className="mt-6 text-lg text-primary underline"
        >
          {t('login.copyLoginLink')}
        </button>
        {shareableLinkCopied && (
          <div>
            <div className="fade-in-text mt-2 inline-block rounded-lg bg-gray-500 px-3 py-1 text-white">
              {t('copiedToClipboard')}
            </div>
          </div>
        )}
        {shareableLinkCopyFailed && (
          <div
            ref={shareableLinkRef}
            className="fade-in-text mt-2 rounded-lg bg-gray-500 px-3 py-1 text-white"
          >
            {loginLink()}
          </div>
        )}
      </div>
    </div>
  )
}

export default LoginUnavailable
