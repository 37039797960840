import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useState, useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, Navigate, Link } from 'react-router-dom'
import { SCHEDULE_PATH } from 'Routes'
import { useBusiness } from 'business/use-business'
import { UserBooking } from 'generated/graphql'
import {
  USER_BOOKINGS_QUERY,
  USER_CANCEL_BOOKING_MUTATION,
} from 'graphql/Bookings'
import { ERROR_CANCELLATION_PERIOD_EXPIRED } from 'graphql/errors'
import { base64UrlDecode } from 'lib/base64Url'
import { businessLogoUrl } from 'lib/business'
import ErrorMessage from 'shared/components/ErrorMessage'
import PageLayout from 'shared/components/PageLayout'
import Spinner from 'shared/components/Spinner'
import BookedEvent from './BookedEvent'
import CancelBookingModal from './CancelBookingModal'

const Bookings = () => {
  function useQueryString() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
  }

  const { business } = useBusiness()
  const query = useQueryString()
  const encodedData = query.get('data')
  const queryParams = encodedData
    ? JSON.parse(base64UrlDecode(encodedData))
    : null
  const { u: userId, o: orderId } = queryParams

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [cancelBookingMutation, { loading: cancelling }] = useMutation(
    USER_CANCEL_BOOKING_MUTATION
  )
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [bookingToCancel, setBookingToCancel] = useState<UserBooking | null>(
    null
  )
  const [
    getBookings,
    {
      error: getBookingsError,
      loading: loadingBookings,
      data: bookingsData,
      refetch: refetchBookings,
    },
  ] = useLazyQuery(USER_BOOKINGS_QUERY)

  useEffect(() => {
    getBookings({
      variables: {
        orderId: orderId,
        userId: userId,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showCancelBookingConfirmation = (booking: UserBooking) => {
    setBookingToCancel(booking)
  }

  const cancelBooking = async (booking: UserBooking) => {
    if (cancelling) {
      return
    }

    setBookingToCancel(null)
    setErrorMessage(null)

    try {
      await cancelBookingMutation({
        variables: {
          userId,
          bookingId: booking.id,
        },
      })

      refetchBookings()
    } catch (error) {
      const apolloError: ApolloError = error as ApolloError
      let errorMessage = t('error.generic')

      console.error(error)

      if (
        apolloError.graphQLErrors.length > 0 &&
        apolloError.graphQLErrors[0].extensions
      ) {
        const errorCode = apolloError.graphQLErrors[0].extensions.code
        switch (errorCode) {
          case ERROR_CANCELLATION_PERIOD_EXPIRED:
            errorMessage = t('error.bookingCancellationPeriodExpired')
            break
        }
      }

      setErrorMessage(errorMessage)
      Sentry.captureException(error)
    }
  }

  if (getBookingsError) {
    console.error(`GraphQL Error. ${getBookingsError.message}`)
    if (errorMessage !== t('error.generic')) {
      setErrorMessage(t('error.generic'))
    }
  }

  if (!userId || !orderId || getBookingsError) {
    return <Navigate to="/" replace={true} />
  }

  return (
    <PageLayout
      headerProps={{
        showBasketIcon: false,
      }}
    >
      <div className="mx-auto flex flex-col md:mt-6">
        <div className="border-gray mx-auto flex w-full flex-col rounded-lg bg-white pt-6 text-center md:max-w-lg md:border md:px-8 md:py-6">
          {business && business.logo && (
            <img
              src={businessLogoUrl(business)}
              alt={business.name}
              className="mx-auto mb-6 inline-block h-12 md:hidden"
            />
          )}

          <div className="w-full select-none bg-primary py-1 text-center text-white md:bg-white md:text-2xl md:font-black md:text-black">
            {t('bookings.myUpcomingEvents')}
          </div>
          <Link to="/" className="mb-6 hidden text-primary underline md:block">
            {t('viewSchedule')}
          </Link>

          {loadingBookings && (
            <div className="mt-4">
              <Spinner text={t('loading')} />
            </div>
          )}

          <div className={errorMessage ? 'my-2' : ''}>
            <ErrorMessage
              visible={errorMessage !== null}
              message={errorMessage}
            />
          </div>

          {!loadingBookings &&
            bookingsData?.userBookings
              .slice()
              .sort(function (a: UserBooking, b: UserBooking) {
                return (
                  Date.parse(a.event.startTime) - Date.parse(b.event.startTime)
                )
              })
              .map((booking: UserBooking, index: number) => (
                <BookedEvent
                  booking={booking}
                  cancelBooking={showCancelBookingConfirmation}
                  key={index}
                />
              ))}

          {bookingsData?.userBookings.length === 0 && (
            <div className="my-2 text-center">
              {t('bookings.noUpcomingBookings')}
            </div>
          )}

          <button
            onClick={() =>
              navigate(SCHEDULE_PATH, {
                replace: true,
              })
            }
            className="mx-auto mt-6 block text-primary underline md:hidden"
          >
            {t('button.viewSchedule')}
          </button>
        </div>
        {bookingToCancel ? (
          <CancelBookingModal
            booking={bookingToCancel}
            cancelBooking={cancelBooking}
            close={() => setBookingToCancel(null)}
          />
        ) : null}
      </div>
    </PageLayout>
  )
}

export default Bookings
