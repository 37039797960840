export const formatDate = ({
  date,
  format,
  timeZone,
  locale = 'en',
}: {
  date: Date
  format: string
  timeZone: string | null
  locale?: string
}) => {
  const monthLong = date.toLocaleDateString(locale, {
    month: 'long',
    timeZone: timeZone || undefined,
  })
  const year = date.toLocaleDateString(locale, {
    year: 'numeric',
    timeZone: timeZone || undefined,
  })
  const weekday = date.toLocaleDateString(locale, {
    weekday: 'short',
    timeZone: timeZone || undefined,
  })
  const weekdayLong = date.toLocaleDateString(locale, {
    weekday: 'long',
    timeZone: timeZone || undefined,
  })
  const day = date.toLocaleDateString(locale, {
    day: 'numeric',
    timeZone: timeZone || undefined,
  })
  const monthShort = date.toLocaleDateString(locale, {
    month: 'short',
    timeZone: timeZone || undefined,
  })
  const time = date.toLocaleString(locale, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZone: timeZone || undefined,
  })

  switch (format) {
    case 'day':
      return `${weekdayLong} ${
        locale === 'en-GB' ? getOrdinal(parseInt(day)) : day
      }`
    case 'month':
      return `${monthLong} ${year}`
    case 'short':
      return `${monthShort} ${day}, ${time}`
    case 'full':
      return `${weekday}, ${
        locale === 'en-GB' ? getOrdinal(parseInt(day)) : day
      } ${monthShort} @ ${time}`
    case 'date':
      return `${date.getDay().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().substr(-2)}`
    default:
      return date.toLocaleDateString(locale, {
        timeZone: timeZone || undefined,
      })
  }
}

export const datesAreSameDay = (date1: Date, date2: Date): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  )
}

export const dateIsInRange = (date: Date, start: Date, end: Date) => {
  const startMidnight = new Date(start).setHours(0, 0, 0, 0)
  const endMidnight = new Date(addDaysToDate(end, 1)).setHours(0, 0, 0, 0)

  return date.getTime() > startMidnight && date.getTime() < endMidnight
}

export const dateIsInWeek = (date: Date, week: Array<Date>): boolean => {
  let result = false

  week.some(function (day) {
    result =
      date.getFullYear() === day.getFullYear() &&
      date.getMonth() === day.getMonth() &&
      date.getDate() === day.getDate()
    return result
  })

  return result
}

export const getDaysForWeekCommencing = (date: Date): Array<Date> => {
  const startDate = new Date(date.getTime()) // Make a copy because JS Date is mutable
  const week = []

  for (let i = 0; i < 7; i++) {
    week.push(new Date(startDate))
    startDate.setDate(startDate.getDate() + 1)
  }

  return week
}

export const getOrdinal = (n: number) => {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export const lessThanOneHourAgo = (date: Date) => {
  const HOUR = 1000 * 60 * 60
  const anHourAgo = Date.now() - HOUR

  return date.getTime() > anHourAgo
}

export const getFirstDayOfThisWeek = () => {
  const date = new Date()
  const day = date.getDay()

  // Get first day of this week
  // day of month - day of week (-6 if Sunday), otherwise +1
  const diff = date.getDate() - day + (day === 0 ? -6 : 1)

  return new Date(date.setDate(diff))
}

export const getFirstDayOfThisMonth = () => {
  const date = new Date()
  return new Date(date.getFullYear(), date.getMonth(), 1)
}

export const getFirstDayOfNextWeek = () => {
  const date = new Date()
  const day = date.getDay()

  // Get first day of this week
  // day of month - day of week (-6 if Sunday), otherwise +1
  const diff = date.getDate() - day + (day === 0 ? -6 : 1)

  return new Date(date.setDate(diff + 7))
}

export const getFirstDayOfNextMonth = () => {
  const date = new Date()
  return new Date(date.getFullYear(), date.getMonth() + 1, 1)
}

export const getPreviousMonday = (date: Date) => {
  const day = date.getDay()
  if (day === 0) {
    return date
  } else {
    const prevMonday = new Date(date.getTime())
    prevMonday.setDate(date.getDate() - (day - 1))
    return prevMonday
  }
}

export const daysInMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}

export const addDaysToDate = (date: Date, days: number) => {
  const newDate = new Date(date.valueOf())
  newDate.setDate(date.getDate() + days)
  return newDate
}

// Taken from date-fns
export const addMonthsToDate = (date: Date, months: number) => {
  const _date = new Date(date.getTime())
  const dayOfMonth = _date.getDate()
  const endOfDesiredMonth = new Date(_date.getTime())
  endOfDesiredMonth.setMonth(_date.getMonth() + months + 1, 0)
  const daysInMonth = endOfDesiredMonth.getDate()
  if (dayOfMonth >= daysInMonth) {
    return endOfDesiredMonth
  } else {
    _date.setFullYear(
      endOfDesiredMonth.getFullYear(),
      endOfDesiredMonth.getMonth(),
      dayOfMonth
    )
    return _date
  }
}
