import { useEffect, ReactNode } from 'react'
import { useBusinessManager } from 'business/use-business-manager'
import { BusinessContext } from './BusinessContext'

export const BusinessProvider = ({ children }: { children: ReactNode }) => {
  const businessManager = useBusinessManager()

  useEffect(() => {
    businessManager.start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BusinessContext.Provider value={businessManager}>
      {children}
    </BusinessContext.Provider>
  )
}
