import { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'

const GUEST_COOKIE = 'bookable_guest'

interface GuestUser {
  firstName: string
  lastName: string
  email: string
  phoneCountryCode: string
  phone: string
}

interface GuestCookie {
  version: string
  data: GuestUser
}

export const useGuestCookie = () => {
  const [cookies, setCookie, removeCookie] = useCookies([GUEST_COOKIE])
  const [cookiedGuest, setCookiedGuest] = useState<GuestCookie | null>(null)

  const setGuestCookie = (data: GuestUser) => {
    setCookie(
      GUEST_COOKIE,
      { version: import.meta.env.VITE_APP_COOKIE_VERSION!, data },
      {
        sameSite: 'strict',
      }
    )
  }

  useEffect(() => {
    if (cookies[GUEST_COOKIE]) {
      if (
        !cookies[GUEST_COOKIE].version ||
        cookies[GUEST_COOKIE].version !==
          import.meta.env.VITE_APP_COOKIE_VERSION
      ) {
        removeCookie(GUEST_COOKIE)
        setCookiedGuest(null)
      } else {
        setCookiedGuest(cookies[GUEST_COOKIE].data)
      }
    }
  }, [cookiedGuest, cookies, removeCookie])

  return { cookiedGuest, setGuestCookie }
}
