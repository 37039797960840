import { ReactNode } from 'react'
import { Business } from 'generated/graphql'
import BasketIcon from 'shared/components/BasketIcon'
import BusinessLogoImage from 'shared/components/BusinessLogoImage'
import UserProfileButton from 'shared/components/UserProfileButton'

export interface DesktopHeaderProps {
  business: Business
  centerContent?: ReactNode
  showBasketIcon?: boolean
  showProfileIcon?: boolean
}

const DesktopHeader = ({
  business,
  centerContent,
  showBasketIcon = false,
  showProfileIcon = true,
}: DesktopHeaderProps) => {
  return (
    <div className="bg-white">
      {centerContent && (
        <div className="mx-auto my-2 hidden pt-4 md:flex md:max-w-3xl md:justify-between lg:hidden">
          <div></div>
          <div className="flex items-center justify-end gap-1">
            {showBasketIcon && business?.currencyCode && (
              <BasketIcon currencyCode={business.currencyCode} />
            )}
            {showProfileIcon && (
              <div className="mr-3">
                <UserProfileButton testid="user-profile-button-md" />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="mx-auto hidden max-w-3xl pb-2 shadow-bottom md:block lg:max-w-full lg:px-8 lg:pt-4">
        <div className="relative h-14">
          <div className="absolute left-0 top-0 hidden h-full items-center lg:flex">
            <BusinessLogoImage business={business} />
          </div>
          <div className="flex-basis-[48rem] mx-auto w-[48rem] min-w-[48rem]">
            {centerContent ? centerContent : <></>}
          </div>
          <div
            className={`hidden h-full pt-4 lg:pt-0 ${
              centerContent ? 'lg:block' : 'md:block'
            } absolute right-0 top-0`}
          >
            <div className="flex h-full items-center justify-end gap-1 pr-3">
              {showBasketIcon && business?.currencyCode && (
                <BasketIcon currencyCode={business.currencyCode} />
              )}
              {showProfileIcon && (
                <UserProfileButton testid="user-profile-button-lg" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DesktopHeader
