import { gql } from '@apollo/client'

// Mutations
export const CREATE_MEMBERSHIP_ORDER_MUTATION = gql`
  mutation CreateMembershipOrder(
    $membershipPlan: MembershipPlanInput!
    $checkoutUrls: CheckoutUrlsInput!
  ) {
    createMembershipOrder(
      input: { membershipPlan: $membershipPlan, checkoutUrls: $checkoutUrls }
    ) {
      membershipOrder {
        status
        reference
      }
      paymentRedirectUrl
    }
  }
`

// Mutations
export const CREATE_BILLING_PORTAL_URL_MUTATION = gql`
  mutation CreateBillingPortalUrl($returnUrl: String!) {
    createBillingPortalUrl(input: { returnUrl: $returnUrl }) {
      url
    }
  }
`
