import { createContext } from 'react'
import { useLoginManager } from 'login/use-login-manager'

export const LoginContext = createContext<ReturnType<typeof useLoginManager>>({
  user: false,
  loading: false,
  errors: [],
  setUser: () => {},
  unsetUser: () => {},
  refetchUser: () => {
    return Promise.resolve()
  },
})
