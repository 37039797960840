import { Event } from 'generated/graphql'

export const RecurringEventType = 'RecurringEvent'

export function shouldShowCapacityInfo(event: Event) {
  if (event.capacity) {
    return event.bookingsCount / event.capacity >= 0.5
  } else {
    return event.bookingsCount > 4
  }
}

export const eventFullyBooked = (event: Event) => {
  return (
    event.bookingsCount &&
    event.capacity &&
    event.bookingsCount >= event.capacity
  )
}

export const eventBookingsAvailable = (event: Event) => {
  return event.capacity ? event.capacity - event.bookingsCount : 1
}

export const pastEvent = (event: Event) => {
  return new Date().getTime() > new Date(event.startTime).getTime()
}

export const eventCancelled = (event: Event) => {
  return event.cancelled
}

export const freeEvent = (event: Event) => {
  return event.price.amount === 0
}

export const eventDuration = (event: Event) => {
  const startTime = new Date(event.startTime)
  const endTime = new Date(event.endTime)
  const eventDurationSeconds = (+endTime - +startTime) / 1000
  const eventDurationFullMinutes = Math.floor(eventDurationSeconds / 60)
  if (eventDurationFullMinutes >= 60) {
    const eventDurationFullHours = Math.floor(eventDurationFullMinutes / 60)
    return {
      hours: eventDurationFullHours,
      minutes: (eventDurationSeconds - eventDurationFullHours * 60 * 60) / 60,
    }
  } else {
    return {
      hours: 0,
      minutes: eventDurationFullMinutes,
    }
  }
}
