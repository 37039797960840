import { gql } from '@apollo/client'

// Queries
export const CURRENT_BUSINESS_QUERY = gql`
  query CurrentBusiness {
    currentBusiness {
      ...BusinessParams
    }
  }
`
