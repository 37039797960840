import { useMutation } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useState, useRef, useEffect, useCallback } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import {
  AUTH_CALLBACK_PATH,
  LOGIN_PATH,
  LOGIN_UNAVAILABLE_PATH,
  CREDITS_PATH,
  CREDITS_CHECKOUT_PATH,
  SCHEDULE_PATH,
} from 'Routes'
import { useBusiness } from 'business/use-business'
import {
  Business,
  SignInAppleInput,
  SignInGoogleInput,
} from 'generated/graphql'
import { SIGN_IN_APPLE_MUTATION } from 'graphql/SignInApple'
import { SIGN_IN_GOOGLE_MUTATION } from 'graphql/SignInGoogle'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { AuthProvider } from 'lib/auth'
import { businessLogoUrl } from 'lib/business'
import { useLogin } from 'login/use-login'
import CloseIcon from 'shared/components/CloseIcon'
import ErrorMessage from 'shared/components/ErrorMessage'
import OverlaySpinner from 'shared/components/OverlaySpinner'
import PageLayout from 'shared/components/PageLayout'
import LoginOverlay from './LoginOverlay'
import LoginProviderButton from './LoginProviderButton'

interface LocationState {
  referrerPath?: string
  signInError?: boolean
  sessionExpired?: boolean
}

const MESSAGE_EVENT_TYPE_SIGN_IN = 'bookableSignIn'
const AUTH_POPUP_WINDOW_WIDTH = 500
const AUTH_POPUP_WINDOW_HEIGHT = 600
const AUTH_BASE_URL = import.meta.env.VITE_APP_BOOKABLE_AUTH_SERVICE

export const LoginValueProp = ({
  business,
}: {
  business: Business | false
}) => {
  const { t } = useTranslation()

  const valueProps = [t('login.valueProp.specialEvents')]

  if (business && business.bookingOptions.creditBundles.length > 0) {
    valueProps.push(t('login.valueProp.credits'))
  }

  if (business && business.bookingOptions.membershipPlans.length > 0) {
    valueProps.push(t('login.valueProp.memberships'))
  }

  return (
    <div className="text-center">
      <div className="my-4 text-lg text-primary">
        {t('login.valueProp.heading')}
      </div>
      {valueProps.map((valueProp, index) => {
        return (
          <div
            className="my-2 mr-3 flex h-6 items-center justify-center font-light"
            key={index}
          >
            <svg
              className="mr-2 h-5 w-5 flex-shrink-0 text-primary"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
            {valueProp}
          </div>
        )
      })}
    </div>
  )
}

const getAuthURL = (provider: AuthProvider, referrerPath: string) => {
  const returnUrl = [window.location.protocol, window.location.host].join('//')

  const returnPath = referrerPath.replace('/', '')
  let path
  const queryParams = [
    `returnUrl=${returnUrl}${AUTH_CALLBACK_PATH}`,
    `returnPath=${returnPath}`,
  ]

  switch (provider) {
    case AuthProvider.Apple:
      path = 'apple-redirect'
      break
    case AuthProvider.Google:
      path = 'google-redirect'
      break
  }

  return path ? `${AUTH_BASE_URL}/${path}?${queryParams.join('&')}` : null
}

const insideFacebookWebView = () => {
  return (
    /FBAN/.test(navigator.userAgent) ||
    /FBAV/.test(navigator.userAgent) ||
    // @ts-ignore
    typeof FB_IAB !== 'undefined' ||
    document.documentElement.classList.contains('in-app-browser') ||
    navigator.userAgent.toLowerCase().includes('instagram')
  )
}

const Login = () => {
  const { t } = useTranslation()
  const { screenX, screenY, windowWidth, windowHeight } = useWindowDimensions()
  const navigate = useNavigate()
  const location = useLocation()
  const locationState = location.state as LocationState
  const {
    referrerPath = LOGIN_PATH,
    signInError = false,
    sessionExpired = false,
  } = locationState || {}
  const { business } = useBusiness()
  const { user, setUser, refetchUser, loading: loadingUser } = useLogin()
  const authPopup = useRef<Window | null>(null)
  const authPopupActiveTimer = useRef<ReturnType<typeof setInterval> | null>(
    null
  )
  const [signInAppleMutation, { error: appleSignInError }] = useMutation(
    SIGN_IN_APPLE_MUTATION
  )
  const [signInGoogleMutation, { error: googleSignInError }] = useMutation(
    SIGN_IN_GOOGLE_MUTATION
  )
  const [showError, shouldShowError] = useState<boolean>(signInError === true)
  const [errorMessage, setErrorMessage] = useState<string>(
    signInError ? t('error.loginFailedGeneric') : t('error.generic')
  )
  const [showLoginOverlay, shouldShowLoginOverlay] = useState(false)
  const [showSpinner, shouldShowSpinner] = useState(false)

  if (signInError) {
    console.error('Redirect sign-in error')
  }

  const onSignInRequestReceived = useCallback(
    (event: MessageEvent) => {
      const signInFailed = ({
        error,
        provider,
      }: {
        error: any
        provider: AuthProvider
      }) => {
        console.error(error)

        shouldShowSpinner(false)
        showErrorMessage(t('error.loginFailedWithProvider', { provider }))
        Sentry.captureException(error, {
          extra: {
            provider,
          },
        })
      }

      const signInWithApple = async (authorization: SignInAppleInput) => {
        shouldShowSpinner(true)

        try {
          const result = await signInAppleMutation({
            variables: {
              code: authorization.code,
              idToken: authorization.idToken,
            },
          })

          shouldShowSpinner(false)

          // console.log(result);

          setUser(result.data.signInApple)
        } catch (error) {
          signInFailed({ error, provider: AuthProvider.Apple })
        }
      }

      const signInWithGoogle = async (authorization: SignInGoogleInput) => {
        shouldShowSpinner(true)

        try {
          const result = await signInGoogleMutation({
            variables: {
              idToken: authorization.idToken,
            },
          })

          shouldShowSpinner(false)

          // console.log(result);

          setUser(result.data.signInGoogle)
        } catch (error) {
          signInFailed({ error, provider: AuthProvider.Google })
        }
      }

      if (event.data?.type === MESSAGE_EVENT_TYPE_SIGN_IN) {
        authPopup.current = null
        shouldShowLoginOverlay(false)

        if (event.data.success) {
          switch (event.data.provider) {
            case AuthProvider.Apple:
              signInWithApple({
                code: event.data.data.code,
                idToken: event.data.data.id_token,
              })
              break
            case AuthProvider.Google:
              signInWithGoogle({ idToken: event.data.data.id_token })
              break
            default:
          }
        } else {
          console.error(
            `${event.data.provider} sign-in failed.`,
            event.data.data.error
          )
        }
      }
    },
    [t, setUser, signInAppleMutation, signInGoogleMutation]
  )

  useEffect(() => {
    if (user) {
      navigate(referrerPath === LOGIN_PATH ? SCHEDULE_PATH : referrerPath, {
        replace: true,
      })
    }
  }, [user, navigate, referrerPath])

  useEffect(() => {
    window.addEventListener('message', onSignInRequestReceived)
    return () => window.removeEventListener('message', onSignInRequestReceived)
  }, [onSignInRequestReceived])

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload)
    return () => {
      window.removeEventListener('beforeunload', handleUnload)
    }
  }, [])

  useEffect(() => {
    refetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUnload = () => {
    if (authPopup.current && !authPopup.current.closed) {
      authPopup.current.close()
      authPopup.current = null
    }
  }

  const close = () => {
    navigate(-1)
  }

  const startLogin = (provider: AuthProvider) => {
    if (provider === AuthProvider.Google && insideFacebookWebView()) {
      navigate(LOGIN_UNAVAILABLE_PATH)
    } else {
      const authUrl = getAuthURL(provider, referrerPath)
      hideErrorMessage()

      if (authUrl) {
        openAuthPopup(authUrl)
      }
    }
  }

  const openAuthPopup = (authUrl: string) => {
    const left = (windowWidth - AUTH_POPUP_WINDOW_WIDTH) / 2 + screenX
    const top = (windowHeight - AUTH_POPUP_WINDOW_HEIGHT) / 2 + screenY

    const newWindow = window.open(
      authUrl,
      '_blank',
      `
      width=${AUTH_POPUP_WINDOW_WIDTH}, 
      height=${AUTH_POPUP_WINDOW_HEIGHT}, 
      top=${top}, 
      left=${left}
      `
    )

    if (newWindow) {
      shouldShowLoginOverlay(true)
      newWindow.focus()
      authPopup.current = newWindow

      authPopupActiveTimer.current = setInterval(function () {
        if (authPopup.current && authPopup.current.closed) {
          clearInterval(
            authPopupActiveTimer.current as ReturnType<typeof setInterval>
          )
          shouldShowLoginOverlay(false)
        }
      }, 1000)
    }
  }

  const focusAuthPopup = () => {
    if (authPopup.current) {
      authPopup.current.focus()
    } else {
      shouldShowLoginOverlay(false)
    }
  }

  const getSessionExpiryMessage = () => {
    if (
      referrerPath === CREDITS_PATH ||
      referrerPath === CREDITS_CHECKOUT_PATH
    ) {
      return t('error.expiredSession')
    } else {
      return t('error.expiredSessionDuringReservation')
    }
  }

  const showErrorMessage = (message: string) => {
    if (message) {
      setErrorMessage(message)
      shouldShowError(true)
    }
  }

  const hideErrorMessage = () => {
    setErrorMessage('')
    shouldShowError(false)
  }

  if (appleSignInError) {
    console.error('Apple Sign-in Error', appleSignInError)
  }

  if (googleSignInError) {
    console.error('Google Sign-in Error', googleSignInError)
  }

  return (
    <PageLayout
      headerProps={{ showProfileIcon: false }}
      pageLoading={loadingUser}
    >
      <div className="relative">
        <CloseIcon onClick={close} />
        <div className="mx-auto flex flex-col md:mt-6">
          <div className="border-gray mx-auto flex w-full flex-col rounded-lg bg-white md:max-w-lg md:border md:p-6">
            {business && business.logo && (
              <img
                src={businessLogoUrl(business)}
                alt={business.name}
                className="mx-auto mt-12 inline-block h-12 md:hidden"
              />
            )}

            <div className="mb-10 mt-12 text-center text-2xl font-black md:mt-6">
              <h1>{t('auth.title')}</h1>
              <div className="mt-3 text-xl">{(business as Business).name}</div>
            </div>

            <div className="mb-5 flex flex-col space-y-2 px-4">
              <LoginProviderButton
                provider={AuthProvider.Apple}
                onClick={startLogin}
              />
              <LoginProviderButton
                provider={AuthProvider.Google}
                onClick={startLogin}
              />
              <div>
                <div className="mt-4"></div>
                <ErrorMessage visible={showError} message={errorMessage} />
                <div>
                  <ErrorMessage
                    visible={sessionExpired && !showError}
                    message={getSessionExpiryMessage()}
                  />
                </div>
              </div>
              <div className="rounded-lg bg-gray-100 pb-2">
                <LoginValueProp business={business} />
              </div>
            </div>
          </div>

          <div className="text-center md:mt-4">
            <Trans
              i18nKey="auth.organizerSignIn"
              components={{
                dashboardLink: (
                  <Link
                    to={import.meta.env.VITE_APP_DASHBOARD_URL}
                    className="underline underline-offset-2"
                  >
                    here
                  </Link>
                ),
              }}
            />
          </div>

          <LoginOverlay
            visible={showLoginOverlay}
            title={t('spinner.titleInProgress', { action: t('login.logIn') })}
            subtitle={t('login.focusWindow')}
            onClick={focusAuthPopup}
          />

          <OverlaySpinner
            visible={showSpinner}
            title={t('login.loggingIn')}
            subtitle={t('spinner.subtitlePleaseWait')}
          />
        </div>
      </div>
    </PageLayout>
  )
}

export default Login
