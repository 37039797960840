import clsx from 'clsx'
interface DateStripNavProps {
  week: Array<Date>
  text: string
  onPrevSelected: () => void
  onNextSelected: () => void
}

const DateStripNav = ({
  week,
  text,
  onPrevSelected,
  onNextSelected,
}: DateStripNavProps) => {
  const todayMidnight = new Date().setHours(0, 0, 0, 0)
  const firstDateMidnight = new Date(week[0]).setHours(0, 0, 0, 0)
  const prevButtonEnabled = firstDateMidnight >= todayMidnight

  function prevSelected() {
    onPrevSelected()
  }

  function nextSelected() {
    onNextSelected()
  }

  return (
    <div className="mb-2 flex w-full justify-between bg-primary text-white">
      <button
        className={`ml-2 ${clsx(!prevButtonEnabled && 'invisible')}`}
        onClick={prevSelected}
        disabled={!prevButtonEnabled}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="mr-0.5 h-8 w-8 stroke-current p-1"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <div className="select-none px-1 py-1.5 capitalize">{text}</div>
      <button className="mr-2" onClick={nextSelected}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="ml-0.5 h-8 w-8 stroke-current p-1"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  )
}

export default DateStripNav
