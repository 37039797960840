import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { CREATE_BILLING_PORTAL_URL_MUTATION } from 'graphql/Membership'
import Spinner from 'shared/components/Spinner'

const ManageMembership = () => {
  const navigate = useNavigate()
  const [createBillingPortalUrl, { error, data }] = useMutation(
    CREATE_BILLING_PORTAL_URL_MUTATION
  )

  useEffect(() => {
    createBillingPortalUrl({
      variables: {
        returnUrl: [window.location.protocol, window.location.host].join('//'),
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (error) {
      navigate('/', {
        replace: true,
      })
    }

    if (data) {
      window.location.href = data.createBillingPortalUrl.url
    }
  }, [error, data, navigate])

  return (
    <div className="flex h-screen justify-center text-primary">
      <Spinner />
    </div>
  )
}

export default ManageMembership
