import { gql } from '@apollo/client'

export const VALIDATE_TRIAL_PASS_REDEMPTION_CODE_MUTATION = gql`
  mutation ValidateTrialPassRedemptionCode($redemptionCode: String!) {
    validateTrialPassRedemptionCode(
      input: { redemptionCode: $redemptionCode }
    ) {
      valid
    }
  }
`
