import { useMemo } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { BOOKING_CONFIRMATION_PATH, CART_PATH } from 'Routes'

const CheckoutSuccess = () => {
  function useQueryString() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQueryString()
  const orderReference = query.get('reference')

  return orderReference ? (
    <Navigate
      to={BOOKING_CONFIRMATION_PATH}
      replace={true}
      state={{ orderReference }}
    />
  ) : (
    <Navigate to={CART_PATH} replace={true} />
  )
}

export default CheckoutSuccess
