import { Link } from 'react-router-dom'
import { Business } from 'generated/graphql'
import { businessLogoUrl } from 'lib/business'

const BusinessLogoImage = ({ business }: { business?: Business }) => {
  const imgSrc = business ? businessLogoUrl(business) : null

  return (
    <div>
      {imgSrc ? (
        <Link to="/">
          <img src={imgSrc} alt={business!.name} className="h-12" />
        </Link>
      ) : (
        <div className="line-clamp-2 max-w-[10rem] text-center text-lg font-medium">
          {business?.name || ''}
        </div>
      )}
    </div>
  )
}

export default BusinessLogoImage
