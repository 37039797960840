import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CART_PATH } from 'Routes'
import { useCart } from 'cart/use-cart'
import { formatMoney, zeroMoney } from 'lib/moneyUtils'

const BasketIcon = ({ currencyCode }: { currencyCode: string }) => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const { cart } = useCart()
  const { cartItems, cartTotal } = cart
  const bookingsCount = cartItems.reduce((n, { quantity }) => n + quantity, 0)

  function viewBasket() {
    navigate(CART_PATH, {
      replace: false,
    })
  }

  if (bookingsCount === 0) {
    return null
  }

  return (
    <button className="mr-2 flex" onClick={viewBasket}>
      <div className="flex items-center rounded-l-3xl border border-primary bg-primary py-2 pl-3 pr-2 text-sm font-semibold text-white">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="mr-1 h-4 w-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
          />
        </svg>
        {bookingsCount}
      </div>
      <div className="rounded-r-3xl border border-l-0 border-black py-2 pl-1 pr-3 text-sm font-semibold">
        {formatMoney(
          cartTotal === 0 ? zeroMoney(currencyCode) : cartTotal,
          i18n.resolvedLanguage
        )}
      </div>
    </button>
  )
}

export default BasketIcon
