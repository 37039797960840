import { gql } from '@apollo/client'

// Queries
export const SCHEDULE_QUERY = gql`
  query Schedule {
    schedule {
      id
      type
      timeZone
      startTime
      endTime
      capacity
      locationName
      name
      creditEligible
      membershipEligible
      bookingsCount
      cancelled
      price {
        ...MoneyParams
      }
      images {
        id
        key
        index
        url
      }
      recurrence
      shareableId {
        shareableId
      }
    }
  }
`

export const SHARED_EVENT_QUERY = gql`
  query SharedEvent($shareableId: String!) {
    sharedEvent(shareableId: $shareableId) {
      ...EventParams
    }
  }
`
