import { PropsWithChildren } from 'react'
import { closeIcon } from 'lib/icons'

type ModalLightboxProps = {
  id: string
  title: string
  close: () => void
}

const ModalLightbox = (props: PropsWithChildren<ModalLightboxProps>) => {
  const { id, title, close, children } = props

  return (
    <>
      <div
        className="fixed bottom-0 left-0 right-0 top-0 z-40 h-screen w-full overflow-hidden bg-gray-700/90 py-12"
        data-testid={`${id}-modal`}
      >
        <div className="absolute left-1/2 top-1/2 max-h-[calc(100vh-3rem)] w-full max-w-2xl -translate-x-1/2 -translate-y-1/2 overflow-y-auto">
          <div className="z-50 mx-4 rounded-lg bg-white p-4 shadow">
            <div className="rounded-t">
              <h3 className="mt-2 text-center text-xl font-semibold">
                {title}
              </h3>
            </div>
            <button
              onClick={close}
              type="button"
              className="absolute right-8 top-4 text-primary"
            >
              {closeIcon()}
            </button>

            <div className="px-2 py-4 pb-2 md:px-6 md:py-6">{children}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ModalLightbox
