import { Buffer } from 'buffer'

function unescape(str: string) {
  return (str + '==='.slice((str.length + 3) % 4))
    .replace(/-/g, '+')
    .replace(/_/g, '/')
}

function escape(str: string) {
  return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

export function base64UrlEncode(str: string) {
  return escape(Buffer.from(str, 'utf8').toString('base64'))
}

export function base64UrlDecode(str: string) {
  return Buffer.from(unescape(str), 'base64').toString('utf8')
}
