import { useEffect, useRef, useState, ReactNode } from 'react'
import { Money } from 'generated/graphql'
import { addMonthsToDate, lessThanOneHourAgo } from 'lib/dateUtils'
import { CART_LOCAL_STORAGE_KEY } from 'localStorage'
import { useLogin } from 'login/use-login'
import {
  CartItem,
  CreditRedemption,
  MembershipBooking,
} from 'shared/constants/cart'
import { CartContext } from './CartContext'

const cartItemsAreSameEvent = (item1: CartItem, item2: CartItem) => {
  return item1.eventId === item2.eventId && item1.startTime === item2.startTime
}

const uuidv4 = (): string => {
  // @ts-ignore
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}

const assignCartItemId = (cartItems: Array<CartItem>): string => {
  let itemId = uuidv4()
  let existingItemWithId =
    cartItems.filter((item) => item.id === itemId).length > 0
  while (existingItemWithId) {
    const newItemId = uuidv4()
    existingItemWithId =
      cartItems.filter((item) => item.id === newItemId).length > 0
    itemId = newItemId
  }
  return itemId
}

export const CartProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useLogin()
  const [items, setItems] = useState<CartItem[]>([])
  const [trialPassRedemptionCode, setTrialPassRedemptionCode] = useState<
    string | null
  >(null)
  const [cartTotal, setCartTotal] = useState<Money | 0>(0)
  const [creditRedemptions, setCreditRedemptions] = useState<
    CreditRedemption[]
  >([])
  const [membershipBookings, setMembershipBookings] = useState<
    MembershipBooking[]
  >([])
  const [membershipQuotaExceeded, setMembershipQuotaExceeded] = useState(false)
  const [freeTrialRedemption, setFreeTrialRedemption] = useState<string | null>(
    null
  )
  const itemsRef = useRef(items)

  itemsRef.current = items

  useEffect(() => {
    const persistedCartJson = localStorage.getItem(CART_LOCAL_STORAGE_KEY)
    if (persistedCartJson) {
      const persistedCart = JSON.parse(persistedCartJson)
      const lastUpdate = new Date(persistedCart.lastUpdate)

      localStorage.removeItem(CART_LOCAL_STORAGE_KEY)

      if (persistedCart.items.length > 0 && lessThanOneHourAgo(lastUpdate)) {
        setItems(persistedCart.items)
      }
    }
  }, [])

  useEffect(() => {
    const total = () => {
      enum ApplicableMembershipQuota {
        None,
        CurrentPeriod,
        NextPeriod,
      }

      const _creditRedemptions: Array<CreditRedemption> = []
      const userCreditsBalance = user ? user.availableCredits.length : 0
      const membership = user ? user.membership : false
      const _membershipBookings: Array<MembershipBooking> = []
      const cartIncludesMembershipEligibleItems = items.some(
        (item) => item.membershipEligible
      )

      let currentPeriodMembershipQuotaExceeded: boolean =
        (membership || false) &&
        membership.currentPeriodBookingQuota < 1 &&
        cartIncludesMembershipEligibleItems === true
      let currentMembershipPeriodBookingQuota = membership
        ? membership.currentPeriodBookingQuota
        : 0
      let nextMembershipPeriodBookingQuota = membership
        ? membership.nextPeriodBookingQuota
        : 0
      let _freeTrialRedemption: string | null = null

      const creditsRedeemed = () => {
        return _creditRedemptions.reduce((accumulator, redemption) => {
          return accumulator + redemption.credits
        }, 0)
      }

      const itemInMembershipPeriod = (
        eventStartTime: Date,
        periodStart: Date,
        periodEnd: Date
      ) => {
        if (membership) {
          return (
            eventStartTime.getTime() > periodStart.getTime() &&
            eventStartTime.getTime() < periodEnd.getTime()
          )
        }

        return false
      }

      const applicableMembershipQuota = (item: CartItem) => {
        if (
          membership &&
          !membership.paymentOverdue &&
          item.membershipEligible
        ) {
          const eventStartTime = new Date(item.startTime)
          const latestEventTimeBookableWithMembership =
            addMonthsToDate(
              new Date(membership.stripeSubscription.currentPeriodEnd),
              1
            ).getTime() - 1000

          if (
            eventStartTime.getTime() > latestEventTimeBookableWithMembership
          ) {
            return ApplicableMembershipQuota.None
          }

          if (
            itemInMembershipPeriod(
              eventStartTime,
              new Date(membership.stripeSubscription.currentPeriodStart),
              new Date(membership.stripeSubscription.currentPeriodEnd)
            )
          ) {
            if (currentMembershipPeriodBookingQuota > 0) {
              return ApplicableMembershipQuota.CurrentPeriod
            }
          }

          if (
            itemInMembershipPeriod(
              eventStartTime,
              new Date(membership.stripeSubscription.currentPeriodEnd),
              addMonthsToDate(
                new Date(membership.stripeSubscription.currentPeriodEnd),
                1
              )
            )
          ) {
            if (nextMembershipPeriodBookingQuota > 0) {
              return ApplicableMembershipQuota.NextPeriod
            }
          }
        }

        return ApplicableMembershipQuota.None
      }

      if (items.length > 0) {
        const itemTotal = items
          .sort((a, b) => b.price.amount - a.price.amount)
          .reduce((accumulator, item) => {
            const useFreeTrialPass =
              user &&
              user.trialPassEligible &&
              trialPassRedemptionCode &&
              item.trialPassEligible
            const membershipQuota = applicableMembershipQuota(item)
            const bookWithMembership =
              membershipQuota !== ApplicableMembershipQuota.None
            const availableCredits = userCreditsBalance - creditsRedeemed()
            let chargeableQuantity = item.quantity

            // Trial Pass
            if (_freeTrialRedemption === null && useFreeTrialPass) {
              _freeTrialRedemption = item.id!
              chargeableQuantity--

              if (chargeableQuantity === 0) {
                return accumulator
              }
            }

            // Membership
            if (bookWithMembership) {
              switch (membershipQuota as ApplicableMembershipQuota) {
                case ApplicableMembershipQuota.CurrentPeriod:
                  currentMembershipPeriodBookingQuota--
                  currentPeriodMembershipQuotaExceeded =
                    currentMembershipPeriodBookingQuota < 0
                  break
                case ApplicableMembershipQuota.NextPeriod:
                  nextMembershipPeriodBookingQuota--
                  break
              }

              _membershipBookings.push({ id: item.id! })
              chargeableQuantity--

              if (chargeableQuantity === 0) {
                return accumulator
              }
            }

            // Credits
            if (availableCredits > 0) {
              const creditQuantity =
                availableCredits < item.quantity
                  ? availableCredits
                  : item.quantity

              if (item.creditEligible && item.price.amount > 0) {
                _creditRedemptions.push({
                  id: item.id!,
                  credits: creditQuantity,
                  value: item.price.amount,
                })
                chargeableQuantity -= creditQuantity
              }
            }

            return accumulator + item.price.amount * chargeableQuantity
          }, 0)

        setCartTotal({
          amount: itemTotal,
          currency: items[0].price.currency,
        })
        setCreditRedemptions(_creditRedemptions)
        setMembershipBookings(_membershipBookings)
        setMembershipQuotaExceeded(currentPeriodMembershipQuotaExceeded)
        setFreeTrialRedemption(_freeTrialRedemption)
      } else {
        setCartTotal(0)
        setCreditRedemptions([])
        setMembershipBookings([])
        setMembershipQuotaExceeded(false)
        setFreeTrialRedemption(null)
      }
    }

    localStorage.setItem(
      CART_LOCAL_STORAGE_KEY,
      JSON.stringify({ lastUpdate: new Date(), items })
    )

    if (!user) {
      setTrialPassRedemptionCode(null)
    }

    total()
  }, [items, trialPassRedemptionCode, user])

  const addToCart = (item: CartItem) => {
    const existingItemIndex = itemsRef.current.findIndex((cartItem) =>
      cartItemsAreSameEvent(cartItem, item)
    )
    if (existingItemIndex === -1) {
      setItems([
        ...itemsRef.current,
        { ...item, id: assignCartItemId(itemsRef.current) },
      ])
    } else {
      itemsRef.current[existingItemIndex].quantity += item.quantity
      setItems([...itemsRef.current])
    }
  }

  const removeFromCart = (item: CartItem) => {
    const newItems = itemsRef.current.filter(
      (_item) => !cartItemsAreSameEvent(_item, item)
    )
    setItems(newItems)
  }

  const emptyCart = () => {
    localStorage.removeItem(CART_LOCAL_STORAGE_KEY)
    setItems([])
    setTrialPassRedemptionCode(null)
  }

  const applyTrialPass = (redemptionCode: string | null) => {
    setTrialPassRedemptionCode(redemptionCode)
  }

  return (
    <CartContext.Provider
      value={{
        cart: {
          cartItems: items,
          cartTotal,
          creditRedemptions,
          membershipBookings,
          membershipQuotaExceeded,
          freeTrialRedemption,
        },
        addToCart,
        removeFromCart,
        emptyCart,
        applyTrialPass,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
