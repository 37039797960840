import { gql } from '@apollo/client'

// Mutations
export const CREATE_CREDIT_ORDER_MUTATION = gql`
  mutation CreateCreditOrder(
    $creditBundle: CreditBundleInput!
    $checkoutUrls: CheckoutUrlsInput!
  ) {
    createCreditOrder(
      input: { creditBundle: $creditBundle, checkoutUrls: $checkoutUrls }
    ) {
      creditOrder {
        status
        reference
      }
      paymentRedirectUrl
    }
  }
`
