import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { CHECKOUT_PATH } from 'Routes'
import CloseIcon from 'shared/components/CloseIcon'
import PageLayout from 'shared/components/PageLayout'

interface LocationState {
  stripeClientSecret: string
  stripeAccountId: string
  stripeKey: string
}

const IFrameCheckout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const state = (location.state as LocationState) || {}
  const { stripeClientSecret, stripeKey, stripeAccountId } = state
  const [stripe, setStripe] = useState<Stripe | null>(null)

  useEffect(() => {
    const initStripe = async () => {
      loadStripe(stripeKey, {
        stripeAccount: stripeAccountId,
      }).then((result) => setStripe(result))
    }

    initStripe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <PageLayout pageLoading={!stripe}>
      <div className="relative">
        <CloseIcon
          onClick={() => navigate(CHECKOUT_PATH)}
          textColor="text-white"
          backgroundColor="bg-primary"
        />

        <EmbeddedCheckoutProvider
          stripe={stripe}
          options={{ clientSecret: stripeClientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      </div>
    </PageLayout>
  )
}

export default IFrameCheckout
