import { gql } from '@apollo/client'

export const USER_BOOKINGS_QUERY = gql`
  query UserBookings($orderId: ID!, $userId: ID!) {
    userBookings(orderId: $orderId, userId: $userId) {
      id
      createdAt
      pricePaid {
        ...MoneyParams
      }
      quantity
      cancelled
      cancellationPeriod
      recurringEventInstance
      event {
        id
        type
        timeZone
        startTime
        endTime
        locationName
        locationUrl
        name
        description
        cancelled
        images {
          ...EventImageParams
        }
      }
    }
  }
`

export const USER_CANCEL_BOOKING_MUTATION = gql`
  mutation UserCancelBooking($bookingId: ID!, $userId: ID!) {
    userCancelBooking(input: { bookingId: $bookingId, userId: $userId }) {
      status
    }
  }
`
