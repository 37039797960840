import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, Link } from 'react-router-dom'
import { SCHEDULE_PATH } from 'Routes'
import { useLogin } from 'login/use-login'
import CloseIcon from 'shared/components/CloseIcon'
import PageLayout from 'shared/components/PageLayout'
import UserProfileButton from 'shared/components/UserProfileButton'

const Confirmation = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { refetchUser } = useLogin()

  useEffect(() => {
    refetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const close = () => {
    navigate(SCHEDULE_PATH, {
      replace: true,
    })
  }

  return (
    <PageLayout>
      <div className="relative">
        <CloseIcon onClick={() => close()} />
        <div className="absolute left-0 top-0 mt-2 md:hidden">
          <UserProfileButton />
        </div>
        <div className="mx-auto flex flex-col md:mt-6">
          <div className="border-gray mx-auto flex w-full flex-col rounded-lg bg-white pt-12 text-center md:max-w-lg md:border md:py-6">
            <div className="single-word-lines md:word-spacing-normal text-2xl font-light">
              {t('creditsConfirmation.purchaseComplete')}
            </div>
            <Link to="/" className="hidden text-primary underline md:block">
              {t('viewSchedule')}
            </Link>

            <div className="my-6 text-primary">
              <svg
                className="inline h-28 w-28"
                fill="none"
                height="15"
                viewBox="0 0 15 15"
                width="15"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  clipRule="evenodd"
                  d="M0 7.5C0 3.35786 3.35786 0 7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5ZM7.0718 10.7106L11.3905 5.31232L10.6096 4.68762L6.92825 9.2893L4.32012 7.11586L3.67993 7.88408L7.0718 10.7106Z"
                  fill="currentColor"
                  fillRule="evenodd"
                />
              </svg>
            </div>

            <div className="mb-6">{t('confirmation.emailMessage')}</div>

            <Link
              to={SCHEDULE_PATH}
              replace
              className="mt-6 block text-primary underline md:hidden"
            >
              {t('viewSchedule')}
            </Link>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Confirmation
