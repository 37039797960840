import { useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  COOKIE_CONSENT_LOCAL_STORAGE_KEY,
  COOKIE_CONSENT_TRUE,
  COOKIE_CONSENT_FALSE,
} from 'localStorage'
import { isInFrame } from 'shared/helpers/iframe'

const CookieNotice = () => {
  const { t } = useTranslation()
  const [cookieConsent, setCookieConsent] = useState(
    localStorage.getItem(COOKIE_CONSENT_LOCAL_STORAGE_KEY)
  )

  const accept = () => {
    localStorage.setItem(COOKIE_CONSENT_LOCAL_STORAGE_KEY, COOKIE_CONSENT_TRUE)
    setCookieConsent(COOKIE_CONSENT_TRUE)
  }

  const reject = () => {
    localStorage.setItem(COOKIE_CONSENT_LOCAL_STORAGE_KEY, COOKIE_CONSENT_FALSE)
    setCookieConsent(COOKIE_CONSENT_FALSE)
  }

  if (isInFrame || cookieConsent !== null) {
    return null
  } else {
    return (
      <div
        className={
          'fixed bottom-0 left-0 w-full border bg-white p-4 shadow-[0_0_6px_2px_rgba(0,0,0,0.1)]'
        }
      >
        <div className="mb-2 text-sm">{t('cookieNotice')}</div>
        <div className="mb-2 text-sm">
          <Trans
            i18nKey="cookiePrivacyLink"
            components={{
              cookiesPolicy: (
                <Link
                  to="https://wearebookable.com/cookies"
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary"
                >
                  Privacy Policy
                </Link>
              ),
            }}
          />
        </div>
        <div className="flex flex-row justify-evenly">
          <button
            className="button-primary m-2 cursor-pointer p-2 text-base"
            onClick={reject}
          >
            {t('reject')}
          </button>
          <button
            className="button-primary m-2 cursor-pointer p-2 text-base"
            onClick={accept}
          >
            {t('accept')}
          </button>
        </div>
      </div>
    )
  }
}

export default CookieNotice
