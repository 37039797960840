interface DesktopBackButtonProps {
  text: string
  onBack: () => void
}

const DesktopBackButton = ({ text, onBack }: DesktopBackButtonProps) => {
  return (
    <button
      className="hidden cursor-pointer items-center md:mb-4 md:mt-6 md:flex"
      onClick={onBack}
    >
      <div className="mr-2 inline h-5 w-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 stroke-current"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </div>
      <div className="text-2xl font-black capitalize">{text}</div>
    </button>
  )
}

export default DesktopBackButton
