import { useEffect, RefObject } from 'react'

export default function useOnClickOutside(
  elementRef: RefObject<any>,
  triggerRef: RefObject<any>,
  handler: (event: MouseEvent | TouchEvent) => void
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!elementRef.current || elementRef.current.contains(event.target)) {
        return
      }

      if (
        triggerRef &&
        (!triggerRef.current || triggerRef.current.contains(event.target))
      ) {
        return
      }
      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }

    // handler function should be wrapped in useCallback
  }, [elementRef, triggerRef, handler])
}
