import { ReactNode } from 'react'
import { useLoginManager } from 'login/use-login-manager'
import { LoginContext } from './LoginContext'

export const LoginProvider = ({ children }: { children: ReactNode }) => {
  const loginManager = useLoginManager()

  return (
    <LoginContext.Provider value={loginManager}>
      {children}
    </LoginContext.Provider>
  )
}
