import { useEffect } from 'react'
import { CookiesProvider } from 'react-cookie'
import { BusinessProvider } from 'context/BusinessProvider'
import { CartProvider } from 'context/CartProvider'
import { CheckoutProvider } from 'context/CheckoutProvider'
import { LoginProvider } from 'context/LoginProvider'
import GqlClient from 'graphql/Client'
import Router from './router'

export default function App() {
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload()
      }
    }
  }, [])

  return (
    <GqlClient>
      <LoginProvider>
        <BusinessProvider>
          <CookiesProvider>
            <CartProvider>
              <CheckoutProvider>
                <Router />
              </CheckoutProvider>
            </CartProvider>
          </CookiesProvider>
        </BusinessProvider>
      </LoginProvider>
    </GqlClient>
  )
}
