import { ReactNode } from 'react'
import { useBusiness } from 'business/use-business'
import { Business } from 'generated/graphql'
import DesktopHeader, {
  DesktopHeaderProps,
} from 'shared/components/DesktopHeader'
import { AccountIdError, ServerError } from 'shared/components/Errors'
import Spinner from 'shared/components/Spinner'

const PageLayout = ({
  headerProps,
  pageLoading = false,
  children,
}: {
  headerProps?: Omit<DesktopHeaderProps, 'business'>
  pageLoading?: boolean
  children: ReactNode
}) => {
  const {
    loading: loadingBusiness,
    business,
    error: businessError,
    invalidAccountId,
  } = useBusiness()

  const ErrorMessage = () => {
    return invalidAccountId ? <AccountIdError /> : <ServerError />
  }

  return (
    <>
      {loadingBusiness || (!business && !businessError) ? (
        <div className="text-gray h-screen">
          <Spinner />
        </div>
      ) : !business ? (
        <ErrorMessage />
      ) : (
        <>
          <DesktopHeader
            {...{
              ...headerProps,
              ...{ business: business as Business },
            }}
          />
          {pageLoading ? (
            <div className="h-screen text-primary">
              <Spinner />
            </div>
          ) : (
            <>{children}</>
          )}
        </>
      )}
    </>
  )
}

export default PageLayout
