import { ReactNode } from 'react'
import CloseIcon from 'shared/components/CloseIcon'
import DesktopBackButton from 'shared/components/DesktopBackButton'
import UserProfileButton from 'shared/components/UserProfileButton'

interface MobileHeaderProps {
  title: string
  onBack: () => void
}
const MobileHeader = ({ title, onBack }: MobileHeaderProps) => {
  return (
    <>
      <CloseIcon onClick={onBack} />

      <div className="absolute left-0 top-0 mt-2 md:hidden">
        <UserProfileButton />
      </div>

      <h1 className="mb-5 text-center text-2xl font-black md:hidden">
        {title}
      </h1>
    </>
  )
}

interface CheckoutLayoutProps {
  title: string
  onBack: () => void
  left: {
    title?: string
    header?: ReactNode
    content: ReactNode
    hidden?: boolean
  }
  right: { title?: string; content: ReactNode; hidden?: boolean }
}

const CheckoutLayout = ({
  title,
  onBack,
  left,
  right,
}: CheckoutLayoutProps) => {
  return (
    <div className="relative mx-auto flex w-full flex-col md:max-w-3xl">
      <DesktopBackButton text={title} onBack={onBack} />

      <div className="flex w-full gap-6">
        {!left.hidden && (
          <div className="relative w-full flex-1 flex-col p-4 pt-12 md:flex md:p-0">
            {right.hidden && <MobileHeader title={title} onBack={onBack} />}

            <div className="border-gray rounded-lg bg-white md:border md:p-6 md:pt-4">
              {left.header}
              {left.title && (
                <div className="hidden text-lg font-bold md:mb-4 md:block">
                  {left.title}
                </div>
              )}
              {left.content}
            </div>
          </div>
        )}

        {!right.hidden && (
          <div className="border-gray w-full flex-col self-start rounded-lg bg-white p-6 pt-12 md:flex md:w-[345px] md:border md:py-4">
            {left.hidden && <MobileHeader title={title} onBack={onBack} />}

            {right.title && (
              <div className="hidden text-lg font-bold md:mb-6 md:block">
                {right.title}
              </div>
            )}
            <div className="w-full">{right.content}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CheckoutLayout
