import { useLazyQuery } from '@apollo/client'
import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { BOOKING_CONFIRMATION_PATH, CHECKOUT_PATH } from 'Routes'
import { CheckoutSession } from 'generated/graphql'
import { CHECKOUT_SESSION_STATUS_QUERY } from 'graphql/BookingOrder'
import PageLayout from 'shared/components/PageLayout'

const PaymentStatusComplete = 'complete'
const PaymentStatusPaid = 'paid'

const paymentComplete = (sessionStatus: CheckoutSession): boolean => {
  return (
    sessionStatus.status === PaymentStatusComplete &&
    sessionStatus.paymentStatus === PaymentStatusPaid
  )
}

const PaymentFailed = () => {
  return (
    <Navigate
      to={CHECKOUT_PATH}
      replace
      state={{
        paymentFailed: true,
      }}
    />
  )
}
const IFrameCheckoutReturn = () => {
  const [sessionStatus, setSessionStatus] = useState<CheckoutSession | null>(
    null
  )
  const [checkoutSessionStatus, { loading, error }] = useLazyQuery(
    CHECKOUT_SESSION_STATUS_QUERY
  )

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const sessionId = urlParams.get('sessionId')

    const getStatus = async () => {
      const result = await checkoutSessionStatus({
        variables: {
          checkoutSessionId: sessionId,
        },
      })

      setSessionStatus(result.data.checkoutSessionStatus)
    }

    getStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (sessionStatus) {
    if (paymentComplete(sessionStatus)) {
      return (
        <Navigate
          to={BOOKING_CONFIRMATION_PATH}
          replace
          state={{
            orderReference: sessionStatus.orderReference,
          }}
        />
      )
    } else {
      return <PaymentFailed />
    }
  }

  if (error) {
    return <PaymentFailed />
  }

  return (
    <PageLayout pageLoading={loading}>
      <></>
    </PageLayout>
  )
}

export default IFrameCheckoutReturn
