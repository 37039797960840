import { ApolloClient, useApolloClient } from '@apollo/client'
import { useEffect, useReducer } from 'react'
import { User } from 'generated/graphql'
import { CURRENT_USER_QUERY } from 'graphql/User'
import { USER_LOCAL_STORAGE_KEY } from 'localStorage'

const getCurrentUser = async ({
  graphqlClient,
  useCache = false,
}: {
  graphqlClient: ApolloClient<any>
  useCache?: boolean
}): Promise<User> => {
  const result = await graphqlClient.query({
    query: CURRENT_USER_QUERY,
    fetchPolicy: useCache ? 'cache-first' : 'network-only',
  })

  return result.data.currentUser
}

type State =
  | { value: 'idle'; context: Record<string, never> }
  | { value: 'valid'; context: { user: User } }
  | { value: 'invalid'; context: { errors?: Array<string> } }
  | { value: 'pending.refetch' }
  | { value: 'pending.set'; context: { user: User } }
  | { value: 'pending.unset'; context: { user: false } }

type Action =
  | { type: 'SET_USER'; payload: User }
  | { type: 'SET_USER.SUCCESS' }
  | { type: 'REFETCH' }
  | { type: 'REFETCH.SUCCESS'; payload: User }
  | { type: 'REFETCH.ERROR' }
  | { type: 'REFETCH.UNAUTHORIZED' }
  | { type: 'UNSET_USER' }
  | { type: 'UNSET_USER.SUCCESS' }

const action = (type: string, payload?: unknown) =>
  ({ type, payload }) as Action

const reducer = (state: State, action: Action): State => {
  // console.log(`login reducer, state: ${state.value}, action: ${action.type}`)

  switch (state.value) {
    case 'invalid':
    case 'idle':
      switch (action.type) {
        case 'SET_USER':
          return {
            value: 'pending.set',
            context: {
              user: action.payload,
            },
          }
        case 'REFETCH':
          return {
            value: 'pending.refetch',
          }
      }
      break
    case 'pending.refetch':
      switch (action.type) {
        case 'REFETCH.SUCCESS':
          return {
            value: 'valid',
            context: {
              user: action.payload,
            },
          }
        case 'REFETCH.ERROR':
          return {
            value: 'invalid',
            context: {},
          }
        case 'REFETCH.UNAUTHORIZED':
          return {
            value: 'pending.unset',
            context: { user: false },
          }
      }
      break
    case 'pending.set':
      switch (action.type) {
        case 'SET_USER.SUCCESS':
          return {
            value: 'valid',
            context: {
              ...state.context,
            },
          }
      }
      break
    case 'pending.unset':
      switch (action.type) {
        case 'UNSET_USER.SUCCESS':
          return {
            value: 'idle',
            context: {},
          }
      }
      break
    case 'valid':
      switch (action.type) {
        case 'UNSET_USER':
          return {
            value: 'pending.unset',
            context: { user: false },
          }
        case 'REFETCH':
          return {
            value: 'pending.refetch',
          }
      }
      break
  }
  return { ...state }
}

export const useLoginManager = (
  defaultState: State = {
    value: 'idle',
    context: {},
  }
) => {
  const graphqlClient = useApolloClient()
  let user: { lastUpdate: string; user: User } | false = false

  try {
    const value = window.localStorage.getItem(USER_LOCAL_STORAGE_KEY)
    if (value) {
      const persistedUser = JSON.parse(value)
      const lastUpdate = new Date(persistedUser.lastUpdate)

      if (lastUpdate && lastUpdate.getTime() > Date.now() - 1000 * 60 * 10) {
        // Only load persisted user data if it's less than 10 minutes old
        user = persistedUser
      }
    }
  } catch {
    // Do nothing. The localStorage value that caused an issue will be cleared below
  }

  if (!user) {
    window.localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
  }

  const initialState = user
    ? ({ value: 'valid', context: { user: user.user } } as State)
    : defaultState
  const [state, dispatch] = useReducer<typeof reducer>(reducer, initialState)

  useEffect(() => {
    // console.log('login manager state: ' + state.value)

    switch (state.value) {
      case 'pending.set':
        localStorage.setItem(
          USER_LOCAL_STORAGE_KEY,
          JSON.stringify({ lastUpdate: new Date(), user: state.context.user })
        )
        dispatch(action('SET_USER.SUCCESS'))
        break
      case 'pending.unset':
        localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
        dispatch(action('UNSET_USER.SUCCESS'))
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value])

  return {
    user: state.value === 'valid' && state.context.user,
    errors: state.value === 'invalid' ? state.context.errors : [],
    loading: state.value === 'pending.refetch',
    setUser: (user: User) => dispatch(action('SET_USER', user)),
    unsetUser: () => dispatch(action('UNSET_USER')),
    refetchUser: async (): Promise<void> => {
      dispatch(action('REFETCH'))

      getCurrentUser({ graphqlClient })
        .then((user) => {
          dispatch(action('REFETCH.SUCCESS', user))
        })
        .catch((error: any) => {
          if (error.message === 'Unauthorized.') {
            dispatch(action('REFETCH.UNAUTHORIZED'))
          } else {
            console.error(error)
            dispatch(action('REFETCH.ERROR'))
          }
        })
    },
  }
}
