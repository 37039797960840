import { createContext } from 'react'
import { useBusinessManager } from 'business/use-business-manager'

export const BusinessContext = createContext<
  ReturnType<typeof useBusinessManager>
>({
  start: () => {},
  refetch: () => {
    return Promise.resolve()
  },
  loading: false,
  business: false,
  error: undefined,
  invalidAccountId: false,
})
