import { useMutation } from '@apollo/client'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SIGN_OUT_MUTATION } from 'graphql/SignOut'
import { useLogin } from 'login/use-login'
import Spinner from 'shared/components/Spinner'

const Logout = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { unsetUser } = useLogin()
  const [signOut, { error, data }] = useMutation(SIGN_OUT_MUTATION)

  useEffect(() => {
    signOut({
      variables: {
        input: {},
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (error || data) {
      unsetUser()

      navigate('/', {
        replace: true,
      })
    }
  }, [error, data, unsetUser, navigate])

  return (
    <div className="flex h-screen justify-center">
      <Spinner text={t('login.loggingOut')} />
    </div>
  )
}

export default Logout
