import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CREDITS_PATH } from 'Routes'
import { useLogin } from 'login/use-login'
import Spinner from 'shared/components/Spinner'

const CancelCheckout = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { refetchUser, loading, user, errors } = useLogin()

  useEffect(() => {
    refetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user) {
      navigate(CREDITS_PATH, {
        replace: true,
      })
    }

    if (errors?.length) {
      navigate('/', {
        replace: true,
      })
    }
  }, [loading, user, errors, navigate])

  return (
    <div className="flex h-screen justify-center">
      <Spinner text={t('checkout.cancelling')} />
    </div>
  )
}

export default CancelCheckout
