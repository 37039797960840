import * as Sentry from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import './i18n'

Sentry.init({
  enabled: import.meta.env.NODE_ENV === 'production',
  dsn: 'https://c49da63972827b609ccf71c1b01b9835@o4505024503742464.ingest.us.sentry.io/4507680162643968',
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 0.5, // Capture 50% of the transactions, reduce in production once volume increases
  environment: import.meta.env.NODE_ENV,
  release: import.meta.env.VITE_APP_VERSION,
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
