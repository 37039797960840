import React, { useState, useRef, ReactNode } from 'react'
import SwiperItem from './SwiperItem'
import { getTouchEventData } from './lib/dom'
import { getRefValue, useStateRef } from './lib/hooks'
import './Swiper.css'

export type Props = {
  items: Array<ReactNode>
}

const MIN_SWIPE_REQUIRED = 40

function Swiper({ items }: Props) {
  const containerRef = useRef<HTMLUListElement>(null)
  const containerWidthRef = useRef(0)
  const minOffsetXRef = useRef(0)
  const currentOffsetXRef = useRef(0)
  const startXRef = useRef(0)
  const [offsetX, setOffsetX, offsetXRef] = useStateRef(0)
  const [isSwiping, setIsSwiping] = useState(false)
  const [currentIdx, setCurrentIdx] = useState(0)

  const onTouchMove = (e: TouchEvent | MouseEvent) => {
    const currentX = getTouchEventData(e).clientX
    const diff = getRefValue(startXRef) - currentX
    let newOffsetX = getRefValue(currentOffsetXRef) - diff

    const maxOffsetX = 0
    const minOffsetX = getRefValue(minOffsetXRef)

    if (newOffsetX > maxOffsetX) {
      newOffsetX = maxOffsetX
    }

    if (newOffsetX < minOffsetX) {
      newOffsetX = minOffsetX
    }

    setOffsetX(newOffsetX)
  }
  const onTouchEnd = () => {
    const currentOffsetX = getRefValue(currentOffsetXRef)
    const containerWidth = getRefValue(containerWidthRef)
    let newOffsetX = getRefValue(offsetXRef)

    const diff = currentOffsetX - newOffsetX

    // we need to check difference in absolute/positive value (if diff is more than 40px)
    if (Math.abs(diff) > MIN_SWIPE_REQUIRED) {
      if (diff > 0) {
        // swipe to the right if diff is positive
        newOffsetX = Math.floor(newOffsetX / containerWidth) * containerWidth
      } else {
        // swipe to the left if diff is negative
        newOffsetX = Math.ceil(newOffsetX / containerWidth) * containerWidth
      }
    } else {
      // remain in the current image
      newOffsetX = Math.round(newOffsetX / containerWidth) * containerWidth
    }

    setIsSwiping(false)
    setOffsetX(newOffsetX)
    setCurrentIdx(Math.abs(newOffsetX / containerWidth))

    window.removeEventListener('touchend', onTouchEnd)
    window.removeEventListener('touchmove', onTouchMove)
    window.removeEventListener('mouseup', onTouchEnd)
    window.removeEventListener('mousemove', onTouchMove)
  }

  const onTouchStart = (
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) => {
    setIsSwiping(true)

    currentOffsetXRef.current = getRefValue(offsetXRef)
    startXRef.current = getTouchEventData(e).clientX

    const containerEl = getRefValue(containerRef)
    const containerWidth = containerEl.offsetWidth

    containerWidthRef.current = containerWidth
    minOffsetXRef.current = containerWidth - containerEl.scrollWidth

    window.addEventListener('touchmove', onTouchMove)
    window.addEventListener('touchend', onTouchEnd)
    window.addEventListener('mousemove', onTouchMove)
    window.addEventListener('mouseup', onTouchEnd)
  }

  const indicatorOnClick = (idx: number) => {
    const containerEl = getRefValue(containerRef)
    const containerWidth = containerEl.offsetWidth

    setCurrentIdx(idx)
    setOffsetX(-(containerWidth * idx))
  }

  const swipeLeft = () => {
    if (currentIdx > 0) {
      const containerEl = getRefValue(containerRef)
      const containerWidth = containerEl.offsetWidth
      const newIdx = currentIdx - 1

      setCurrentIdx(newIdx)
      setOffsetX(-(containerWidth * newIdx))
    }
  }

  const swipeRight = () => {
    if (currentIdx < items.length - 1) {
      const containerEl = getRefValue(containerRef)
      const containerWidth = containerEl.offsetWidth
      const newIdx = currentIdx + 1

      setCurrentIdx(newIdx)
      setOffsetX(-(containerWidth * newIdx))
    }
  }

  return (
    <div
      className="swiper-container"
      onTouchStart={onTouchStart}
      onMouseDown={onTouchStart}
    >
      <ul
        ref={containerRef}
        className={`swiper-list ${isSwiping ? 'is-swiping' : ''}`}
        style={{ transform: `translate3d(${offsetX}px, 0, 0)` }}
      >
        {items.map((item, idx) => (
          <SwiperItem
            key={idx}
            item={item}
            canSwipeLeft={idx !== 0}
            canSwipeRight={idx < items.length - 1}
            swipeLeft={swipeLeft}
            swipeRight={swipeRight}
          />
        ))}
      </ul>
      <ul
        className="swiper-indicator"
        style={items.length < 2 ? { visibility: 'hidden' } : undefined}
      >
        {items.map((_item, idx) => (
          <li
            key={idx}
            className={`swiper-indicator-item ${
              currentIdx === idx ? 'active' : ''
            }`}
            onClick={() => indicatorOnClick(idx)}
            data-testid="indicator"
          />
        ))}
      </ul>
    </div>
  )
}

export default Swiper
