import { datesAreSameDay } from 'lib/dateUtils'

interface DateStripDayProps {
  date: Date
  inThePast: boolean
  locale: string
  selected: boolean
  onDateSelected: (selectedDate: Date) => void
}

const DateStripDay = ({
  date,
  inThePast,
  locale,
  selected,
  onDateSelected,
}: DateStripDayProps) => {
  function onClick(date: Date) {
    onDateSelected(date)
  }

  return (
    <button
      className={`flex-1 pb-2 pt-2 ${
        selected ? 'rounded-t-lg bg-primary text-white md:rounded-lg' : ''
      } ${inThePast ? 'cursor-default text-gray-300' : ''}`}
      onClick={() => {
        if (!inThePast) {
          onClick(date)
        }
      }}
    >
      <div className="select-none text-center text-xs">
        {date.toLocaleDateString(locale, { weekday: 'short' })}
      </div>
      <div className="select-none text-center font-semibold">
        {date.getDate()}
      </div>
    </button>
  )
}

interface DateStripProps {
  week: Array<Date>
  selectedDate: Date
  locale?: string
  active: boolean
  showNavArrows: boolean
  onDateSelected: (selectedDate: Date) => void
  onPrevSelected: () => void
  onNextSelected: () => void
}

const DateStrip = ({
  week,
  selectedDate,
  locale = 'en',
  active,
  showNavArrows,
  onDateSelected,
  onPrevSelected,
  onNextSelected,
}: DateStripProps) => {
  const now = new Date()
  const todayStart = now.setHours(0, 0, 0, 0)
  const todayMidnight = new Date().setHours(0, 0, 0, 0)
  const firstDateMidnight = new Date(week[0]).setHours(0, 0, 0, 0)
  const prevButtonEnabled = firstDateMidnight >= todayMidnight

  function dateSelected(selectedDate: Date) {
    onDateSelected(selectedDate)
  }

  function prevSelected() {
    onPrevSelected()
  }

  function nextSelected() {
    onNextSelected()
  }

  return (
    <div className="w-full">
      <div className="flex w-full">
        {showNavArrows && (
          <div className="flex items-center">
            <button
              className={`{w-9 ml-2 h-9 rounded-full ${
                prevButtonEnabled ? 'hover:bg-gray-100' : ''
              }`}
              onClick={prevSelected}
              disabled={!prevButtonEnabled}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mr-0.5 h-8 w-8 stroke-current p-1 text-gray-300"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </button>
          </div>
        )}
        {week.map((date, index) => (
          <DateStripDay
            key={index}
            date={date}
            inThePast={date.getTime() < todayStart}
            locale={locale}
            selected={active && datesAreSameDay(date, selectedDate)}
            onDateSelected={dateSelected}
          />
        ))}
        {showNavArrows && (
          <div className="flex items-center">
            <button
              className="mr-2 h-9 w-9 rounded-full hover:bg-gray-100"
              onClick={nextSelected}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="ml-0.5 h-8 w-8 stroke-current p-1 text-gray-300"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default DateStrip
