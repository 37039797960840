import clsx from 'clsx'
import { ChangeEvent, ReactNode } from 'react'

interface TextInputProps {
  name: string
  value: string
  placeholder: string
  onChange(e: ChangeEvent<HTMLInputElement>): void
  error: string | null
  comment?: string | null
  rightIcon?: ReactNode
  type?: string
  autocomplete?: string
  autocorrect?: string
  spellcheck?: boolean
  autoCapitalize?: string
}
const TextInput = ({
  name,
  value,
  placeholder,
  onChange,
  error,
  comment,
  rightIcon,
  type = 'text',
  autocomplete = 'off',
  autocorrect = 'off',
  spellcheck = false,
  autoCapitalize = undefined,
}: TextInputProps) => {
  return (
    <div className="relative">
      <input
        id={name}
        name={name}
        type={type}
        value={value}
        autoComplete={autocomplete}
        autoCorrect={autocorrect}
        spellCheck={spellcheck}
        autoCapitalize={
          autoCapitalize === undefined
            ? type === 'text'
              ? 'on'
              : 'off'
            : autoCapitalize
        }
        className={`peer h-10 w-full border-0 border-b-2 border-gray-300 pl-0 text-gray-900 placeholder-transparent focus:border-primary focus:ring-transparent ${clsx(rightIcon && 'pr-8')}`}
        placeholder={placeholder}
        onChange={onChange}
        data-testid={name}
      />
      {rightIcon && <div className="absolute right-0 top-2">{rightIcon}</div>}
      <label
        htmlFor={name}
        className="absolute -top-3.5 left-0 text-sm text-gray-600 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-focus:-top-3.5 peer-focus:text-sm peer-focus:text-gray-600"
      >
        {placeholder}
      </label>
      {error && <div className="mt-1 text-xs text-primary">{error}</div>}
      {comment && <div className="text-xs text-gray-400">{comment}</div>}
    </div>
  )
}

export default TextInput
