import { useTranslation } from 'react-i18next'
import { Event, User } from 'generated/graphql'
import { formatMoney } from 'lib/moneyUtils'

const PriceText = ({ event, user }: { event: Event; user: User | false }) => {
  const { t, i18n } = useTranslation()

  const priceText = (text: string) => {
    return <span>{text}</span>
  }

  if (event.price.amount === 0) {
    return priceText(t('free'))
  }

  if (event.membershipEligible && user && user.membership) {
    return priceText(t('member'))
  }

  if (event.creditEligible && user && user.availableCredits.length > 0) {
    return priceText(t('credits.totalCredits', { count: 1 }))
  }

  return priceText(formatMoney(event.price, i18n.resolvedLanguage))
}

export default PriceText
