import { useMutation } from '@apollo/client'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { CHECKOUT_PATH } from 'Routes'
import { CANCEL_BOOKING_ORDER_PAYMENT_MUTATION } from 'graphql/BookingOrder'
import { useLogin } from 'login/use-login'
import Spinner from 'shared/components/Spinner'

const CancelCheckout = () => {
  function useQueryString() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
  }

  const { t } = useTranslation()
  const query = useQueryString()
  const orderId = query.get('o')
  const navigate = useNavigate()
  const { refetchUser } = useLogin()
  const [cancelPayment, { error, data }] = useMutation(
    CANCEL_BOOKING_ORDER_PAYMENT_MUTATION
  )

  useEffect(() => {
    if (orderId) {
      cancelPayment({
        variables: {
          orderId,
        },
      })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!orderId || error || data) {
      refetchUser().then(() =>
        navigate(CHECKOUT_PATH, {
          replace: true,
        })
      )
    }
  }, [orderId, error, data, refetchUser, navigate])

  return (
    <div className="flex h-screen justify-center">
      <Spinner text={t('checkout.cancelling')} />
    </div>
  )
}

export default CancelCheckout
