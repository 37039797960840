import { useTranslation } from 'react-i18next'
import { UserBooking } from 'generated/graphql'
import ModalLightbox from 'shared/components/ModalLightbox'
import BookedEvent from './BookedEvent'

interface CancelBookingModalProps {
  booking?: UserBooking
  cancelBooking: (booking: UserBooking) => void
  close: () => void
}

const CancelBookingModal = ({
  booking,
  cancelBooking,
  close,
}: CancelBookingModalProps) => {
  const { t } = useTranslation()

  if (!booking) {
    return null
  }

  return (
    <ModalLightbox
      id="cancel-booking"
      title={t('bookings.cancelModal.title')}
      close={close}
    >
      <div className="mb-4">
        <BookedEvent booking={booking} />
      </div>
      <button
        onClick={() => cancelBooking(booking)}
        type="button"
        className="button-primary"
      >
        {t('button.cancelBooking')}
      </button>

      <div className="text-xs text-gray-400">
        <p className="my-2">
          {t('bookings.cancelModal.details1', {
            cancellationPeriod: booking.cancellationPeriod,
          })}
        </p>
        <p>
          {t('bookings.cancelModal.details2', {
            cancellationPeriod: booking.cancellationPeriod,
          })}
        </p>
      </div>
    </ModalLightbox>
  )
}

export default CancelBookingModal
