import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CART_PATH, SCHEDULE_PATH } from 'Routes'
import { useBusiness } from 'business/use-business'
import { useCart } from 'cart/use-cart'
import { Event, User } from 'generated/graphql'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { formatDate } from 'lib/dateUtils'
import {
  shouldShowCapacityInfo,
  eventFullyBooked,
  eventBookingsAvailable,
  pastEvent,
  eventCancelled,
  freeEvent,
} from 'lib/eventUtils'
import { mapPinIcon } from 'lib/icons'
import { useLogin } from 'login/use-login'
import { DurationText } from 'pages/schedule/Duration'
import { ScheduleLocationState } from 'pages/schedule/Schedule'
import CloseIcon from 'shared/components/CloseIcon'
import DesktopBackButton from 'shared/components/DesktopBackButton'
import Swiper from 'shared/components/Swiper/Swiper'
import { CartItem } from 'shared/constants/cart'
import { eventImageSort } from 'shared/helpers/event-image-sort'
import PriceText from '../PriceText'
import QuantityPicker from '../QuantityPicker'

const maximumPermittedBookings = (event: Event, bookingsInCart: number) => {
  const freeEvent = event.price.amount === 0
  if (freeEvent && bookingsInCart > 0) {
    // We only allow single bookings for free events
    return 0
  }

  return freeEvent
    ? 1
    : event.capacity
      ? event.capacity - event.bookingsCount - bookingsInCart
      : 50 - bookingsInCart
}

const eventInstancesInCart = (cartItems: CartItem[], event: Event) => {
  return cartItems
    ? cartItems.filter(function (item: CartItem) {
        return (
          item.eventId === event.id &&
          new Date(item.startTime).getTime() ===
            new Date(event.startTime).getTime()
        )
      })
    : []
}

const EventHeader = ({
  event,
  user,
  quantity,
  maxQuantity,
  eventBookingsInCart,
  canAddToCart,
  incrementQuantity,
  decrementQuantity,
  startReservation,
  close,
}: {
  event: Event
  user: User | false
  quantity: number
  maxQuantity: number
  eventBookingsInCart: number
  canAddToCart: boolean
  incrementQuantity: () => void
  decrementQuantity: () => void
  startReservation: () => void
  close: () => void
}) => {
  const { t, i18n } = useTranslation()
  const { isMobile, windowWidth } = useWindowDimensions()
  const eventImages =
    event.images.length > 0
      ? event.images
          .slice()
          .sort(eventImageSort)
          .map((image) => image.url)
      : [import.meta.env.VITE_APP_EVENT_IMAGE_DEFAULT]

  const getCapacityInfo = (event: Event) => {
    if (eventFullyBooked(event)) {
      return (
        <div className="text-center text-lg text-white">{t('fullyBooked')}</div>
      )
    } else if (shouldShowCapacityInfo(event)) {
      if (event.bookingsCount) {
        return (
          <div className="text-center text-lg text-white">
            {t('registrations.registrations', { count: event.bookingsCount })}

            {event.capacity && (
              <>
                <div></div>
                {t('spotsLeft', { count: eventBookingsAvailable(event) })}
              </>
            )}
          </div>
        )
      }
    }
    return null
  }

  const capacityInfo = getCapacityInfo(event)

  return (
    <div className="flex">
      <div
        className="relative w-full md:flex md:w-1/2"
        style={{ height: isMobile ? windowWidth : '' }}
      >
        {isMobile && (
          <>
            <CloseIcon
              onClick={close}
              textColor="text-white"
              backgroundColor="bg-primary"
            />
            <Swiper
              items={eventImages.map((image, index) => {
                const children = []

                if (index === 0) {
                  if (capacityInfo) {
                    children.push(
                      <div
                        key={children.length}
                        className="absolute left-1/2 top-4 w-2/3 -translate-x-1/2 overflow-visible rounded-lg bg-[#00000080] px-4 py-1 font-semibold text-white"
                      >
                        {capacityInfo}
                      </div>
                    )
                  }

                  children.push(
                    <div
                      key={children.length}
                      className="absolute bottom-5 w-full p-2 text-center uppercase text-white md:hidden"
                    >
                      <div className="text-2xl font-medium uppercase">
                        {formatDate({
                          date: new Date(event.startTime),
                          format: 'full',
                          timeZone: event.timeZone,
                          locale: i18n.resolvedLanguage,
                        })}
                      </div>
                      <div className="pt-3 text-3xl font-semibold">
                        {event.name}
                      </div>
                    </div>
                  )
                }

                return (
                  <div
                    key={`image-${index}`}
                    className="swiper-img bg-cover bg-center"
                    style={{
                      backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%), url(${image})`,
                    }}
                    draggable={false}
                  >
                    {children}
                  </div>
                )
              })}
            />
          </>
        )}

        {!isMobile && (
          <Swiper
            items={eventImages.map((image, index) => {
              const children =
                index === 0 && capacityInfo ? (
                  <div
                    key="capacity-info"
                    className="absolute left-1/2 top-4 w-2/3 -translate-x-1/2 overflow-visible rounded-lg bg-[#00000080] px-4 py-1 font-semibold text-white"
                  >
                    {capacityInfo}
                  </div>
                ) : null

              return (
                <div className="relative" key={index}>
                  <img
                    src={image}
                    alt="Event"
                    className="swiper-img aspect-square h-auto w-full rounded-lg"
                    draggable={false}
                  />
                  {children}
                </div>
              )
            })}
          />
        )}
      </div>

      <div className="hidden flex-1 flex-col pl-4 md:flex">
        <div className="flex flex-grow justify-center">
          <div className="self-center text-center">
            <div className="text-lg uppercase">
              {formatDate({
                date: new Date(event.startTime),
                format: 'full',
                timeZone: event.timeZone,
                locale: i18n.resolvedLanguage,
              })}
            </div>
            <div className="pt-1 text-xl font-semibold">{event.name}</div>
          </div>
        </div>
        <div className="flex">
          {!pastEvent(event) && !eventCancelled(event) && (
            <div className="w-full self-end rounded-lg border border-gray-200 px-3 py-1">
              <EventReservationControl
                event={event}
                user={user}
                quantity={quantity}
                maxQuantity={maxQuantity}
                eventBookingsInCart={eventBookingsInCart}
                canAddToCart={canAddToCart}
                incrementQuantity={incrementQuantity}
                decrementQuantity={decrementQuantity}
                startReservation={startReservation}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
interface EventReservationControlProps {
  event: Event
  user: User | false
  quantity: number
  maxQuantity: number
  eventBookingsInCart: number
  canAddToCart: boolean
  incrementQuantity: () => void
  decrementQuantity: () => void
  startReservation: () => void
}

const EventReservationControl = ({
  event,
  user,
  quantity,
  maxQuantity,
  eventBookingsInCart,
  canAddToCart,
  incrementQuantity,
  decrementQuantity,
  startReservation,
}: EventReservationControlProps) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="mt-2 flex content-center justify-between">
        <div className="flex items-center font-semibold capitalize">
          <span className="mr-1">{t('price')}:</span>
          <PriceText event={event} user={user} />
        </div>

        <QuantityPicker
          quantity={quantity}
          maxQuantity={maxQuantity}
          incrementQuantity={incrementQuantity}
          decrementQuantity={decrementQuantity}
        />
      </div>

      <button
        onClick={startReservation}
        disabled={!canAddToCart}
        className={`w-full ${
          canAddToCart ? 'bg-primary' : 'bg-gray-400'
        } mb-1 mt-4 rounded-md py-3 text-lg font-semibold text-white`}
      >
        {t('button.reserve')}
      </button>
      {maxQuantity === 0 && !freeEvent(event) && eventBookingsInCart > 0 && (
        <div className="text-center text-sm text-gray-400">
          {t('event.maxBookingsInBasket', {
            count: eventBookingsInCart,
          })}
        </div>
      )}
      {maxQuantity === 0 && freeEvent(event) && (
        <div className="text-center text-sm text-gray-400">
          {t('event.maxOneBookingForFreeEvent')}
        </div>
      )}
    </>
  )
}

const MobileLocation = ({
  name = null,
  url = null,
}: {
  name?: string | null
  url?: string | null
}) => {
  if (name) {
    if (url) {
      return (
        <a
          href={url}
          target="_blank"
          className="flex items-center justify-center pt-3 font-semibold text-blue md:hidden"
          rel="noreferrer"
        >
          {mapPinIcon()}
          <div className="ml-1">{name}</div>
        </a>
      )
    } else {
      return (
        <div className="flex items-center justify-center pt-3 font-semibold md:hidden">
          {mapPinIcon()}
          <div className="ml-1">{name}</div>
        </div>
      )
    }
  } else {
    return null
  }
}

const DesktopLocation = ({
  name = null,
  url = null,
}: {
  name?: string | null
  url?: string | null
}) => {
  if (name) {
    if (url) {
      return (
        <a
          href={url}
          target="_blank"
          className="hidden items-center justify-center font-semibold text-blue md:block"
          rel="noreferrer"
        >
          {name}
        </a>
      )
    } else {
      return (
        <div className="hidden items-center justify-center md:block">
          {name}
        </div>
      )
    }
  } else {
    return null
  }
}

const EventDetails = ({
  event,
  scheduleLocationState,
}: {
  event: Event
  scheduleLocationState?: ScheduleLocationState
}) => {
  const { t } = useTranslation()
  const shareableLinkRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const { business } = useBusiness()
  const { cart, addToCart } = useCart()
  const { cartItems } = cart
  const { user } = useLogin()
  const descriptionRef = useRef<HTMLDivElement>(null)
  const [quantity, setQuantity] = useState(1)
  const [eventBookingsInCart, setEventBookingsInCart] = useState(0)
  const [maxQuantity, setMaxQuantity] = useState(0)
  const [canAddToCart, setCanAddToCart] = useState(false)
  const [shareableLinkCopied, setShareableLinkCopied] = useState(false)
  const [shareableLinkCopyFailed, setShareableLinkCopyFailed] = useState(false)
  const [descriptionExpandable, setDescriptionExpandable] = useState(false)
  const [descriptionExpanded, setDescriptionExpanded] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (shareableLinkCopyFailed && shareableLinkRef.current) {
      const range = document.createRange()
      range.selectNodeContents(shareableLinkRef.current)

      const sel = window.getSelection()!
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }, [shareableLinkCopyFailed, shareableLinkRef])

  useEffect(() => {
    if (event) {
      const instancesInCart = eventInstancesInCart(cartItems, event)
      const bookingsInCart =
        instancesInCart && instancesInCart.length > 0
          ? instancesInCart[0].quantity
          : 0
      const quantityRemaining = maximumPermittedBookings(event, bookingsInCart)
      setEventBookingsInCart(bookingsInCart)
      setMaxQuantity(quantityRemaining)
      setCanAddToCart(!(eventFullyBooked(event) || quantityRemaining === 0))
    } else {
      navigate(SCHEDULE_PATH, {
        replace: true,
      })
    }
  }, [navigate, user, event, cartItems])

  useEffect(() => {
    if (
      descriptionRef.current?.scrollHeight &&
      descriptionRef.current?.clientHeight
    ) {
      setDescriptionExpandable(
        descriptionRef.current?.scrollHeight >
          descriptionRef.current?.clientHeight
      )
    }
  }, [descriptionRef])

  const startReservation = () => {
    addToCart({
      eventId: event.id,
      name: event.name,
      startTime: event.startTime,
      timeZone: event.timeZone,
      price: {
        amount: event.price.amount,
        currency: event.price.currency,
      },
      creditEligible: event.creditEligible,
      membershipEligible: event.membershipEligible,
      trialPassEligible: event.trialPassEligible,
      image:
        event.images.length > 0
          ? event.images.slice().sort(eventImageSort)[0].url
          : import.meta.env.VITE_APP_EVENT_IMAGE_DEFAULT,
      quantity,
    })

    navigate(CART_PATH, {
      replace: true,
      state: scheduleLocationState?.eventFilterState,
    })
  }

  const close = () => {
    navigate(SCHEDULE_PATH, {
      replace: true,
      state: scheduleLocationState?.eventFilterState,
    })
  }

  const incrementQuantity = () => {
    if (quantity < maxQuantity) {
      setQuantity(quantity + 1)
    }
  }

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1)
    }
  }

  const copyShareableLink = () => {
    navigator.clipboard.writeText(window.location.href).then(
      () => {
        setShareableLinkCopyFailed(false)
        setShareableLinkCopied(true)
      },
      () => {
        setShareableLinkCopyFailed(true)
      }
    )
  }

  const getShareableLinkText = () => {
    if (shareableLinkCopied) {
      return (
        <div className="relative">
          <div className="invisible py-1">{t('copyEventLink')}</div>
          <div className="fade-in-text absolute left-0 top-0 rounded-lg bg-gray-500 px-3 py-1 text-white">
            {t('copiedToClipboard')}
          </div>
        </div>
      )
    } else {
      return <div className="py-1">{t('copyEventLink')}</div>
    }
  }

  const toggleDescriptionExpanded = () => {
    if (descriptionExpandable) {
      setDescriptionExpanded(!descriptionExpanded)
    }
  }

  if (!event) {
    return null
  }

  return (
    <div className="relative mx-auto flex w-full flex-col pb-6 md:max-w-3xl">
      <DesktopBackButton text={event.name} onBack={close} />

      <EventHeader
        event={event}
        user={user}
        quantity={quantity}
        maxQuantity={maxQuantity}
        eventBookingsInCart={eventBookingsInCart}
        canAddToCart={canAddToCart}
        incrementQuantity={incrementQuantity}
        decrementQuantity={decrementQuantity}
        startReservation={startReservation}
        close={close}
      />
      <div className="px-5 md:px-0">
        {!pastEvent(event) && !eventCancelled(event) && (
          <div className="md:hidden">
            <EventReservationControl
              event={event}
              user={user}
              quantity={quantity}
              maxQuantity={maxQuantity}
              eventBookingsInCart={eventBookingsInCart}
              canAddToCart={canAddToCart}
              incrementQuantity={incrementQuantity}
              decrementQuantity={decrementQuantity}
              startReservation={startReservation}
            />
          </div>
        )}

        <MobileLocation name={event.locationName} url={event.locationUrl} />

        <div className="pt-6 font-semibold">
          {`${t('time.duration')}: `}
          <DurationText event={event!} />
        </div>

        <div
          ref={descriptionRef}
          className={`whitespace-pre-line ${
            descriptionExpanded ? '' : 'line-clamp-6'
          } pt-3`}
        >
          {event!.description}
        </div>
        {descriptionExpandable && (
          <div className="w-full text-center">
            <button className="underline" onClick={toggleDescriptionExpanded}>
              {descriptionExpanded
                ? t('button.showLess')
                : t('button.showMore')}
            </button>
          </div>
        )}

        {event.locationName && (
          <>
            <div className="mt-6 hidden text-lg font-semibold md:block">
              {t('eventLocation')}
            </div>

            <DesktopLocation
              name={event.locationName}
              url={event.locationUrl}
            />
          </>
        )}

        {!pastEvent(event) && (
          <>
            <div className="mt-6 text-lg font-semibold">{t('shareEvent')}</div>
            <div className="flex">
              {getShareableLinkText()}
              <button
                className="ml-2 text-blue"
                onClick={() => copyShareableLink()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                  />
                </svg>
              </button>
            </div>
            {shareableLinkCopyFailed && (
              <div
                ref={shareableLinkRef}
                className="fade-in-text rounded-lg bg-gray-500 px-3 py-1 text-white"
              >
                {window.location.href}
              </div>
            )}

            {business && business.bookingCancellationPeriod > 0 && (
              <>
                <div className="mt-4 text-lg font-semibold">
                  {t('cancellationPolicy')}
                </div>

                <div className="py-1">
                  {t('cancellationPolicyDetails', {
                    cancellationPeriod: business.bookingCancellationPeriod,
                  })}
                </div>
              </>
            )}
          </>
        )}

        {pastEvent(event) && (
          <div className="mt-2 text-center text-gray-400">{t('pastEvent')}</div>
        )}

        {eventCancelled(event) && (
          <div className="mt-2 text-center text-gray-400">
            {t('eventCancelled')}
          </div>
        )}
      </div>
    </div>
  )
}

export default EventDetails
