interface ICountry {
  dialingCode: string
  trunkCode: string | null
  name: string
}

interface ICountryCode {
  [P: string]: ICountry
}

export const countryCodes: ICountryCode = {
  AD: { dialingCode: '376', trunkCode: null, name: 'Andorra (+376)' },
  AE: {
    dialingCode: '971',
    trunkCode: '0',
    name: 'United Arab Emirates (+971)',
  },
  AF: { dialingCode: '93', trunkCode: '0', name: 'Afghanistan (+93)' },
  // AG: { dialingCode: '1', trunkCode: null, name: 'Antigua & Barbuda (+1)' },
  // AI: { dialingCode: '1', trunkCode: null, name: 'Anguilla (+1)' },
  AL: { dialingCode: '355', trunkCode: '0', name: 'Albania (+355)' },
  AM: { dialingCode: '374', trunkCode: null, name: 'Armenia (+374)' },
  AO: { dialingCode: '244', trunkCode: null, name: 'Angola (+244)' },
  AR: { dialingCode: '54', trunkCode: '0', name: 'Argentina (+54)' },
  // AS: { dialingCode: '1', trunkCode: null, name: 'American Samoa (+1)' },
  AT: { dialingCode: '43', trunkCode: '0', name: 'Austria (+43)' },
  AU: { dialingCode: '61', trunkCode: '0', name: 'Australia (+61)' },
  AW: { dialingCode: '297', trunkCode: null, name: 'Aruba (+297)' },
  AX: { dialingCode: '358', trunkCode: null, name: 'Åland Islands (+358)' },
  AZ: { dialingCode: '994', trunkCode: '8', name: 'Azerbaijan (+994)' },
  BA: {
    dialingCode: '387',
    trunkCode: '0',
    name: 'Bosnia & Herzegovina (+387)',
  },
  // BB: { dialingCode: '1', trunkCode: null, name: 'Barbados (+1)' },
  BD: { dialingCode: '880', trunkCode: '0', name: 'Bangladesh (+880)' },
  BE: { dialingCode: '32', trunkCode: '0', name: 'Belgium (+32)' },
  BF: { dialingCode: '226', trunkCode: null, name: 'Burkina Faso (+226)' },
  BG: { dialingCode: '359', trunkCode: '0', name: 'Bulgaria (+359)' },
  BH: { dialingCode: '973', trunkCode: null, name: 'Bahrain (+973)' },
  BI: { dialingCode: '257', trunkCode: null, name: 'Burundi (+257)' },
  BJ: { dialingCode: '229', trunkCode: null, name: 'Benin (+229)' },
  BL: { dialingCode: '590', trunkCode: null, name: 'St Barthélemy (+590)' },
  // BM: { dialingCode: '1', trunkCode: null, name: 'Bermuda (+1)' },
  BN: { dialingCode: '673', trunkCode: null, name: 'Brunei (+673)' },
  BO: { dialingCode: '591', trunkCode: '0', name: 'Bolivia (+591)' },
  BQ: {
    dialingCode: '599',
    trunkCode: null,
    name: 'Bonaire, Sint Eustatius and Saba (+599)',
  },
  BR: { dialingCode: '55', trunkCode: '0', name: 'Brazil (+55)' },
  // BS: { dialingCode: '1', trunkCode: null, name: 'Bahamas (+1)' },
  BT: { dialingCode: '975', trunkCode: null, name: 'Bhutan (+975)' },
  BW: { dialingCode: '267', trunkCode: null, name: 'Botswana (+267)' },
  BY: { dialingCode: '375', trunkCode: '8', name: 'Belarus (+375)' },
  BZ: { dialingCode: '501', trunkCode: null, name: 'Belize (+501)' },
  CA: { dialingCode: '1', trunkCode: null, name: 'Canada (+1)' },
  // CC: {
  //   dialingCode: '61',
  //   trunkCode: null,
  //   name: 'Cocos (Keeling) Islands (+61)',
  // },
  CD: {
    dialingCode: '243',
    trunkCode: null,
    name: 'Democratic Republic of the Congo (+243)',
  },
  CF: {
    dialingCode: '236',
    trunkCode: null,
    name: 'Central African Republic (+236)',
  },
  CG: { dialingCode: '242', trunkCode: null, name: 'Congo (+242)' },
  CH: { dialingCode: '41', trunkCode: '0', name: 'Switzerland (+41)' },
  CI: { dialingCode: '225', trunkCode: null, name: "Côte d'Ivoire (+225)" },
  CK: { dialingCode: '682', trunkCode: null, name: 'Cook Islands (+682)' },
  CL: { dialingCode: '56', trunkCode: null, name: 'Chile (+56)' },
  CM: { dialingCode: '237', trunkCode: null, name: 'Cameroon (+237)' },
  CN: { dialingCode: '86', trunkCode: null, name: 'China (+86)' },
  CO: { dialingCode: '57', trunkCode: null, name: 'Colombia (+57)' },
  CR: { dialingCode: '506', trunkCode: null, name: 'Costa Rica (+506)' },
  CU: { dialingCode: '53', trunkCode: null, name: 'Cuba (+53)' },
  CV: { dialingCode: '238', trunkCode: null, name: 'Cape Verde (+238)' },
  CW: { dialingCode: '599', trunkCode: null, name: 'Curaçao (+599)' },
  // CX: { dialingCode: '61', trunkCode: null, name: 'Christmas Island (+61)' },
  CY: { dialingCode: '357', trunkCode: null, name: 'Cyprus (+357)' },
  CZ: { dialingCode: '420', trunkCode: null, name: 'Czechia (+420)' },
  DE: { dialingCode: '49', trunkCode: '0', name: 'Germany (+49)' },
  DJ: { dialingCode: '253', trunkCode: null, name: 'Djibouti (+253)' },
  DK: { dialingCode: '45', trunkCode: null, name: 'Denmark (+45)' },
  // DM: { dialingCode: '1', trunkCode: null, name: 'Dominica (+1)' },
  // DO: { dialingCode: '1', trunkCode: null, name: 'Dominican Republic (+1)' },
  DZ: { dialingCode: '213', trunkCode: null, name: 'Algeria (+213)' },
  EC: { dialingCode: '593', trunkCode: null, name: 'Ecuador (+593)' },
  EE: { dialingCode: '372', trunkCode: null, name: 'Estonia (+372)' },
  EG: { dialingCode: '20', trunkCode: '0', name: 'Egypt (+20)' },
  // EH: { dialingCode: '212', trunkCode: null, name: 'Western Sahara (+212)' },
  ER: { dialingCode: '291', trunkCode: null, name: 'Eritrea (+291)' },
  ES: { dialingCode: '34', trunkCode: null, name: 'Spain (+34)' },
  ET: { dialingCode: '251', trunkCode: null, name: 'Ethiopia (+251)' },
  FI: { dialingCode: '358', trunkCode: '0', name: 'Finland (+358)' },
  FJ: { dialingCode: '679', trunkCode: null, name: 'Fiji (+679)' },
  FK: {
    dialingCode: '500',
    trunkCode: null,
    name: 'Falkland Islands (Islas Malvinas) (+500)',
  },
  FM: { dialingCode: '691', trunkCode: null, name: 'Micronesia (+691)' },
  FO: { dialingCode: '298', trunkCode: null, name: 'Faroe Islands (+298)' },
  FR: { dialingCode: '33', trunkCode: '0', name: 'France (+33)' },
  GA: { dialingCode: '241', trunkCode: null, name: 'Gabon (+241)' },
  GB: { dialingCode: '44', trunkCode: '0', name: 'United Kingdom (+44)' },
  // GD: { dialingCode: '1', trunkCode: null, name: 'Grenada (+1)' },
  GE: { dialingCode: '995', trunkCode: '0', name: 'Georgia (+995)' },
  GF: { dialingCode: '594', trunkCode: null, name: 'French Guiana (+594)' },
  // GG: { dialingCode: '44', trunkCode: null, name: 'Guernsey (+44)' },
  GH: { dialingCode: '233', trunkCode: null, name: 'Ghana (+233)' },
  GI: { dialingCode: '350', trunkCode: null, name: 'Gibraltar (+350)' },
  GL: { dialingCode: '299', trunkCode: null, name: 'Greenland (+299)' },
  GM: { dialingCode: '220', trunkCode: null, name: 'Gambia (+220)' },
  GN: { dialingCode: '224', trunkCode: null, name: 'Guinea (+224)' },
  GP: { dialingCode: '590', trunkCode: null, name: 'Guadeloupe (+590)' },
  GQ: { dialingCode: '240', trunkCode: null, name: 'Equatorial Guinea (+240)' },
  GR: { dialingCode: '30', trunkCode: null, name: 'Greece (+30)' },
  // GS: {
  //   dialingCode: '30',
  //   trunkCode: null,
  //   name: 'South Georgia & South Sandwich Islands (+500)',
  // },
  GT: { dialingCode: '502', trunkCode: null, name: 'Guatemala (+502)' },
  // GU: { dialingCode: '1', trunkCode: null, name: 'Guam (+1)' },
  GW: { dialingCode: '245', trunkCode: null, name: 'Guinea-Bissau (+245)' },
  GY: { dialingCode: '592', trunkCode: null, name: 'Guyana (+592)' },
  HK: { dialingCode: '852', trunkCode: null, name: 'Hong Kong (+852)' },
  HN: { dialingCode: '504', trunkCode: null, name: 'Honduras (+504)' },
  HR: { dialingCode: '385', trunkCode: '0', name: 'Croatia (+385)' },
  HT: { dialingCode: '509', trunkCode: null, name: 'Haiti (+509)' },
  HU: { dialingCode: '36', trunkCode: '06', name: 'Hungary (+36)' },
  ID: { dialingCode: '62', trunkCode: '0', name: 'Indonesia (+62)' },
  IE: { dialingCode: '353', trunkCode: '0', name: 'Ireland (+353)' },
  IL: { dialingCode: '972', trunkCode: '0', name: 'Israel (+972)' },
  // IM: { dialingCode: '44', trunkCode: null, name: 'Isle of Man (+44)' },
  IN: { dialingCode: '91', trunkCode: '0', name: 'India (+91)' },
  IO: {
    dialingCode: '246',
    trunkCode: null,
    name: 'British Indian Ocean Territory (+246)',
  },
  IQ: { dialingCode: '964', trunkCode: null, name: 'Iraq (+964)' },
  IR: { dialingCode: '98', trunkCode: '0', name: 'Iran (+98)' },
  IS: { dialingCode: '354', trunkCode: null, name: 'Iceland (+354)' },
  IT: { dialingCode: '39', trunkCode: null, name: 'Italy (+39)' },
  // JE: { dialingCode: '44', trunkCode: null, name: 'Jersey (+44)' },
  // JM: { dialingCode: '1', trunkCode: null, name: 'Jamaica (+1)' },
  JO: { dialingCode: '962', trunkCode: '0', name: 'Jordan (+962)' },
  JP: { dialingCode: '81', trunkCode: '0', name: 'Japan (+81)' },
  KE: { dialingCode: '254', trunkCode: '0', name: 'Kenya (+254)' },
  KG: { dialingCode: '996', trunkCode: null, name: 'Kyrgyzstan (+996)' },
  KH: { dialingCode: '855', trunkCode: '0', name: 'Cambodia (+855)' },
  KI: { dialingCode: '686', trunkCode: null, name: 'Kiribati (+686)' },
  KM: { dialingCode: '269', trunkCode: null, name: 'Comoros (+269)' },
  // KN: { dialingCode: '1', trunkCode: null, name: 'St Kitts & Nevis (+1)' },
  KR: { dialingCode: '82', trunkCode: '0', name: 'South Korea (+82)' },
  KW: { dialingCode: '965', trunkCode: null, name: 'Kuwait (+965)' },
  // KY: { dialingCode: '1', trunkCode: null, name: 'Cayman Islands (+1)' },
  KZ: { dialingCode: '7', trunkCode: '8', name: 'Kazakhstan (+7)' },
  LA: { dialingCode: '856', trunkCode: '0', name: 'Laos (+856)' },
  LB: { dialingCode: '961', trunkCode: null, name: 'Lebanon (+961)' },
  // LC: { dialingCode: '1', trunkCode: null, name: 'St Lucia (+1)' },
  LI: { dialingCode: '423', trunkCode: null, name: 'Liechtenstein (+423)' },
  LK: { dialingCode: '94', trunkCode: '0', name: 'Sri Lanka (+94)' },
  LR: { dialingCode: '231', trunkCode: null, name: 'Liberia (+231)' },
  LS: { dialingCode: '266', trunkCode: null, name: 'Lesotho (+266)' },
  LT: { dialingCode: '370', trunkCode: '8', name: 'Lithuania (+370)' },
  LU: { dialingCode: '352', trunkCode: null, name: 'Luxembourg (+352)' },
  LV: { dialingCode: '371', trunkCode: null, name: 'Latvia (+371)' },
  LY: { dialingCode: '218', trunkCode: null, name: 'Libya (+218)' },
  MA: { dialingCode: '212', trunkCode: '0', name: 'Morocco (+212)' },
  MC: { dialingCode: '377', trunkCode: null, name: 'Monaco (+377)' },
  MD: { dialingCode: '373', trunkCode: '0', name: 'Moldova (+373)' },
  ME: { dialingCode: '382', trunkCode: '0', name: 'Montenegro (+382)' },
  MF: { dialingCode: '590', trunkCode: null, name: 'St Martin (+590)' },
  MG: { dialingCode: '261', trunkCode: null, name: 'Madagascar (+261)' },
  MH: { dialingCode: '692', trunkCode: null, name: 'Marshall Islands (+692)' },
  MK: { dialingCode: '389', trunkCode: '0', name: 'North Macedonia (+389)' },
  ML: { dialingCode: '223', trunkCode: null, name: 'Mali (+223)' },
  MM: { dialingCode: '95', trunkCode: '0', name: 'Myanmar (+95)' },
  MN: { dialingCode: '976', trunkCode: '01', name: 'Mongolia (+976)' },
  MO: { dialingCode: '853', trunkCode: null, name: 'Macau (+853)' },
  // MP: {
  //   dialingCode: '1',
  //   trunkCode: null,
  //   name: 'Northern Mariana Islands (+1)',
  // },
  MQ: { dialingCode: '596', trunkCode: null, name: 'Martinique (+596)' },
  MR: { dialingCode: '222', trunkCode: null, name: 'Mauritania (+222)' },
  // MS: { dialingCode: '1', trunkCode: null, name: 'Montserrat (+1)' },
  MT: { dialingCode: '356', trunkCode: null, name: 'Malta (+356)' },
  MU: { dialingCode: '230', trunkCode: null, name: 'Mauritius (+230)' },
  MV: { dialingCode: '960', trunkCode: null, name: 'Maldives (+960)' },
  MW: { dialingCode: '265', trunkCode: null, name: 'Malawi (+265)' },
  MX: { dialingCode: '52', trunkCode: '01', name: 'Mexico (+52)' },
  MY: { dialingCode: '60', trunkCode: '0', name: 'Malaysia (+60)' },
  MZ: { dialingCode: '258', trunkCode: null, name: 'Mozambique (+258)' },
  NA: { dialingCode: '264', trunkCode: null, name: 'Namibia (+264)' },
  NC: { dialingCode: '687', trunkCode: null, name: 'New Caledonia (+687)' },
  NE: { dialingCode: '227', trunkCode: null, name: 'Niger (+227)' },
  NF: { dialingCode: '672', trunkCode: null, name: 'Norfolk Island (+672)' },
  NG: { dialingCode: '234', trunkCode: '0', name: 'Nigeria (+234)' },
  NI: { dialingCode: '505', trunkCode: null, name: 'Nicaragua (+505)' },
  NL: { dialingCode: '31', trunkCode: '0', name: 'Netherlands (+31)' },
  NO: { dialingCode: '47', trunkCode: null, name: 'Norway (+47)' },
  NP: { dialingCode: '977', trunkCode: '0', name: 'Nepal (+977)' },
  NR: { dialingCode: '674', trunkCode: null, name: 'Nauru (+674)' },
  NU: { dialingCode: '683', trunkCode: null, name: 'Niue (+683)' },
  NZ: { dialingCode: '64', trunkCode: '0', name: 'New Zealand (+64)' },
  OM: { dialingCode: '968', trunkCode: null, name: 'Oman (+968)' },
  PA: { dialingCode: '507', trunkCode: null, name: 'Panama (+507)' },
  PE: { dialingCode: '51', trunkCode: '0', name: 'Peru (+51)' },
  PF: { dialingCode: '689', trunkCode: null, name: 'French Polynesia (+689)' },
  PG: { dialingCode: '675', trunkCode: null, name: 'Papua New Guinea (+675)' },
  PH: { dialingCode: '63', trunkCode: '0', name: 'Philippines (+63)' },
  PK: { dialingCode: '92', trunkCode: '0', name: 'Pakistan (+92)' },
  PL: { dialingCode: '48', trunkCode: null, name: 'Poland (+48)' },
  PM: {
    dialingCode: '508',
    trunkCode: null,
    name: 'St Pierre & Miquelon (+508)',
  },
  // PN: { dialingCode: '64', trunkCode: null, name: 'Pitcairn Islands (+64)' },
  // PR: { dialingCode: '1', trunkCode: null, name: 'Puerto Rico (+1)' },
  PS: {
    dialingCode: '970',
    trunkCode: null,
    name: 'Palestinian Territories (+970)',
  },
  PT: { dialingCode: '351', trunkCode: null, name: 'Portugal (+351)' },
  PW: { dialingCode: '680', trunkCode: null, name: 'Palau (+680)' },
  PY: { dialingCode: '595', trunkCode: '0', name: 'Paraguay (+595)' },
  QA: { dialingCode: '974', trunkCode: null, name: 'Qatar (+974)' },
  RE: { dialingCode: '262', trunkCode: null, name: 'Réunion (+262)' },
  RO: { dialingCode: '40', trunkCode: '0', name: 'Romania (+40)' },
  RS: { dialingCode: '381', trunkCode: '0', name: 'Serbia (+381)' },
  RU: { dialingCode: '7', trunkCode: '8', name: 'Russia (+7)' },
  RW: { dialingCode: '250', trunkCode: '0', name: 'Rwanda (+250)' },
  SA: { dialingCode: '966', trunkCode: '0', name: 'Saudi Arabia (+966)' },
  SB: { dialingCode: '677', trunkCode: null, name: 'Solomon Islands (+677)' },
  SC: { dialingCode: '248', trunkCode: null, name: 'Seychelles (+248)' },
  SD: { dialingCode: '249', trunkCode: '0', name: 'Sudan (+249)' },
  SE: { dialingCode: '46', trunkCode: '0', name: 'Sweden (+46)' },
  SG: { dialingCode: '65', trunkCode: null, name: 'Singapore (+65)' },
  SH: { dialingCode: '290', trunkCode: null, name: 'St Helena (+290)' },
  SI: { dialingCode: '386', trunkCode: '0', name: 'Slovenia (+386)' },
  SJ: {
    dialingCode: '47',
    trunkCode: null,
    name: 'Svalbard & Jan Mayen (+47)',
  },
  SK: { dialingCode: '421', trunkCode: '0', name: 'Slovakia (+421)' },
  SL: { dialingCode: '232', trunkCode: null, name: 'Sierra Leone (+232)' },
  SM: { dialingCode: '378', trunkCode: null, name: 'San Marino (+378)' },
  SN: { dialingCode: '221', trunkCode: null, name: 'Senegal (+221)' },
  SO: { dialingCode: '252', trunkCode: null, name: 'Somalia (+252)' },
  SR: { dialingCode: '597', trunkCode: null, name: 'Suriname (+597)' },
  SS: { dialingCode: '211', trunkCode: null, name: 'South Sudan (+211)' },
  ST: {
    dialingCode: '239',
    trunkCode: null,
    name: 'São Tomé & Príncipe (+239)',
  },
  SV: { dialingCode: '503', trunkCode: null, name: 'El Salvador (+503)' },
  // SX: { dialingCode: '1', trunkCode: null, name: 'Sint Maarten (+1)' },
  SZ: { dialingCode: '268', trunkCode: null, name: 'Eswatini (+268)' },
  // TC: {
  //   dialingCode: '1',
  //   trunkCode: null,
  //   name: 'Turks & Caicos Islands (+1)',
  // },
  TD: { dialingCode: '235', trunkCode: null, name: 'Chad (+235)' },
  TG: { dialingCode: '228', trunkCode: null, name: 'Togo (+228)' },
  TH: { dialingCode: '66', trunkCode: '0', name: 'Thailand (+66)' },
  TJ: { dialingCode: '992', trunkCode: null, name: 'Tajikistan (+992)' },
  TK: { dialingCode: '690', trunkCode: null, name: 'Tokelau (+690)' },
  TL: { dialingCode: '670', trunkCode: null, name: 'Timor-Leste (+670)' },
  TM: { dialingCode: '993', trunkCode: '8', name: 'Turkmenistan (+993)' },
  TN: { dialingCode: '216', trunkCode: null, name: 'Tunisia (+216)' },
  TO: { dialingCode: '676', trunkCode: null, name: 'Tonga (+676)' },
  TR: { dialingCode: '90', trunkCode: '0', name: 'Turkey (+90)' },
  // TT: { dialingCode: '1', trunkCode: null, name: 'Trinidad & Tobago (+1)' },
  TV: { dialingCode: '688', trunkCode: null, name: 'Tuvalu (+688)' },
  TW: { dialingCode: '886', trunkCode: '0', name: 'Taiwan (+886)' },
  TZ: { dialingCode: '255', trunkCode: '0', name: 'Tanzania (+255)' },
  UA: { dialingCode: '380', trunkCode: '0', name: 'Ukraine (+380)' },
  UG: { dialingCode: '256', trunkCode: null, name: 'Uganda (+256)' },
  US: { dialingCode: '1', trunkCode: null, name: 'United States (+1)' },
  UY: { dialingCode: '598', trunkCode: null, name: 'Uruguay (+598)' },
  UZ: { dialingCode: '998', trunkCode: '8', name: 'Uzbekistan (+998)' },
  VA: { dialingCode: '379', trunkCode: null, name: 'Vatican City (+379)' },
  // VC: {
  //   dialingCode: '1',
  //   trunkCode: null,
  //   name: 'St Vincent & Grenadines (+1)',
  // },
  VE: { dialingCode: '58', trunkCode: '0', name: 'Venezuela (+58)' },
  // VG: {
  //   dialingCode: '1',
  //   trunkCode: null,
  //   name: 'British Virgin Islands (+1)',
  // },
  // VI: { dialingCode: '1', trunkCode: null, name: 'US Virgin Islands (+1)' },
  VN: { dialingCode: '84', trunkCode: '0', name: 'Vietnam (+84)' },
  VU: { dialingCode: '678', trunkCode: null, name: 'Vanuatu (+678)' },
  WF: { dialingCode: '681', trunkCode: null, name: 'Wallis & Futuna (+681)' },
  WS: { dialingCode: '685', trunkCode: null, name: 'Samoa (+685)' },
  XK: { dialingCode: '383', trunkCode: null, name: 'Kosovo (+383)' },
  YE: { dialingCode: '967', trunkCode: null, name: 'Yemen (+967)' },
  YT: { dialingCode: '262', trunkCode: null, name: 'Mayotte (+262)' },
  ZA: { dialingCode: '27', trunkCode: '0', name: 'South Africa (+27)' },
  ZM: { dialingCode: '260', trunkCode: null, name: 'Zambia (+260)' },
  ZW: { dialingCode: '263', trunkCode: null, name: 'Zimbabwe (+263)' },
}

export const createPhoneCountryCode = (isoCode: string = 'GB') => {
  const countryCode = Object.prototype.hasOwnProperty.call(
    countryCodes,
    isoCode
  )
    ? isoCode
    : 'GB'
  return `${countryCode} +${countryCodes[countryCode].dialingCode}`
}
