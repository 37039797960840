/** @type {import('tailwindcss').Config} */

import formsPlugin from '@tailwindcss/forms'
import { fontFamily as _fontFamily } from 'tailwindcss/defaultTheme'

export default {
  content: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  theme: {
    colors: ({ colors }) => ({
      primary: 'var(--primary-color, #E41162)',
      secondary: '#11E493',
      lightGray: '#F4F7FC',
      transparent: 'transparent',
      current: 'currentColor',
      white: '#FFFFFF',
      offWhite: '#FDFDFD',
      black: '#262626',
      darkBlue: '#4A4C67',
      blue: '#0076FF',
      green: '#36B73E',
      red: colors.red,
      gray: colors.gray,
    }),
    screens: {
      wix: '320px',
      sm: '375px',
      md: '768px',
      lg: '1100px',
    },
    extend: {
      fontFamily: {
        redhat: ['"Red+Hat+Display"', ..._fontFamily.sans],
      },
      boxShadow: {
        md: '0 4px 10px 2px rgba(0, 0, 0, 0.06)',
        lg: '0 2px 40px 0 rgba(0, 0, 0, 0.06)',
        bottom: '0 0 0 0 rgb(0 0 0 / 0.1), 0 2px 3px -1px rgb(0 0 0 / 0.1)',
      },
    },
  },
  plugins: [formsPlugin],
}
