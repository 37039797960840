import { Business } from 'generated/graphql'

export const businessLogoUrl = (business: Business) => {
  return business.logo
    ? [
        import.meta.env.VITE_APP_BOOKABLE_CDN,
        'uploads',
        business.id,
        'logo',
        business.logo,
      ].join('/')
    : undefined
}
