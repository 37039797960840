import { closeIcon, closeIconSmall } from 'lib/icons'

interface CloseIconProps {
  visible?: boolean
  textColor?: string
  backgroundColor?: string
  onClick: () => void
}

const CloseIcon = ({
  visible = true,
  textColor = 'text-primary',
  backgroundColor,
  onClick,
}: CloseIconProps) => {
  if (visible) {
    return (
      <button
        className={`${textColor} absolute right-0 top-0 z-10 h-8 w-8 ${
          backgroundColor
            ? `p-1.5 ${backgroundColor} rounded-3xl bg-opacity-80`
            : ''
        } mr-2 mt-2 md:hidden`}
        onClick={onClick}
      >
        {backgroundColor ? closeIconSmall() : closeIcon()}
      </button>
    )
  } else {
    return null
  }
}

export default CloseIcon
