import { useMutation, ApolloError } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useState, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate, useLocation, Link, Navigate } from 'react-router-dom'
import {
  CREDITS_PATH,
  CREDITS_CHECKOUT_SUCCESS_PATH,
  CREDITS_CHECKOUT_CANCEL_PATH,
  LOGIN_PATH,
} from 'Routes'
import { useBusiness } from 'business/use-business'
import { Business, CreditBundle, CreditOrderResponse } from 'generated/graphql'
import { CREATE_CREDIT_ORDER_MUTATION } from 'graphql/CreditOrder'
import { UPDATE_USER_WITH_LOGIN_MUTATION } from 'graphql/User'
import {
  ERROR_CREDIT_BUNDLE_DISCONTINUED,
  ERROR_PAYMENT_UNAVAILABLE,
} from 'graphql/errors'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { formatMoney } from 'lib/moneyUtils'
import {
  COOKIE_CONSENT_LOCAL_STORAGE_KEY,
  COOKIE_CONSENT_TRUE,
} from 'localStorage'
import { useLogin } from 'login/use-login'
import CheckoutForm from 'pages/checkout/CheckoutForm'
import CheckoutLayout from 'shared/components/CheckoutLayout'
import ErrorMessage from 'shared/components/ErrorMessage'
import OverlaySpinner from 'shared/components/OverlaySpinner'
import PageLayout from 'shared/components/PageLayout'
import {
  initialCheckoutFormData,
  validateCheckoutForm,
} from 'shared/helpers/checkout'
import { creditBundleInputFromCreditBundle } from 'shared/helpers/graphql'
import { useGuestCookie } from 'shared/hooks/use-guest-cookie'
import {
  CheckoutFormData,
  CheckoutFormValidationErrors,
} from 'shared/interface/checkout'

interface LocationState {
  bundle: CreditBundle
}

const CreditCheckout = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const { isMobile } = useWindowDimensions()
  const location = useLocation()
  const locationState = (location.state as LocationState) || {}
  const { bundle } = locationState
  const { user, setUser } = useLogin()
  const { business } = useBusiness()
  const { cookiedGuest } = useGuestCookie()
  const cookieConsent =
    localStorage.getItem(COOKIE_CONSENT_LOCAL_STORAGE_KEY) ===
    COOKIE_CONSENT_TRUE
  const [formData, setFormData] = useState<CheckoutFormData>({
    firstName: '',
    lastName: '',
    email: '',
    phoneCountryCode: '',
    phoneNumber: '',
  })
  const [validationErrors, setValidationErrors] =
    useState<CheckoutFormValidationErrors>({
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
    })
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [showSpinner, shouldShowSpinner] = useState(false)

  const [updateUserWithLoginMutation] = useMutation(
    UPDATE_USER_WITH_LOGIN_MUTATION
  )
  const [createOrderMutation] = useMutation(CREATE_CREDIT_ORDER_MUTATION)

  useEffect(() => {
    setFormData(
      initialCheckoutFormData({
        user,
        business: business as Business,
        cookieConsent,
        cookiedGuest,
      })
    )
  }, [cookiedGuest]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!bundle) {
      navigate(CREDITS_PATH)
    }
  }, [bundle, navigate])

  const close = () => {
    navigate(CREDITS_PATH, {
      replace: true,
    })
  }

  const handleCheckoutError = ({
    apolloError,
    message,
  }: {
    apolloError?: ApolloError
    message?: string
  }) => {
    let errorMessage = message ?? t('error.generic')

    if (apolloError && apolloError.message === 'Unauthorized.') {
      navigate(LOGIN_PATH, {
        replace: true,
        state: {
          referrerPath: location.pathname,
          sessionExpired: true,
        },
      })
      return
    }

    if (
      apolloError &&
      apolloError?.graphQLErrors.length > 0 &&
      apolloError.graphQLErrors[0].extensions
    ) {
      console.error('graphQLError', apolloError.graphQLErrors[0].extensions)

      const errorCode = apolloError.graphQLErrors[0].extensions.code
      switch (errorCode) {
        case ERROR_PAYMENT_UNAVAILABLE:
          errorMessage = t('error.paymentUnavailable')
          break
        case ERROR_CREDIT_BUNDLE_DISCONTINUED:
          errorMessage = t('credits.bundleUnavailable')
          break
        default:
          Sentry.captureException(apolloError)
      }
    }

    shouldShowSpinner(false)
    setErrorMessage(errorMessage)
  }

  const clearErrors = () => {
    setValidationErrors({
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
    })
    setErrorMessage(null)
  }

  const createOrder = async () => {
    const createOrderResult = await createOrderMutation({
      variables: {
        creditBundle: creditBundleInputFromCreditBundle(bundle),
        checkoutUrls: {
          success: window.location.origin + CREDITS_CHECKOUT_SUCCESS_PATH,
          cancel: window.location.origin + CREDITS_CHECKOUT_CANCEL_PATH,
          return: null,
        },
      },
    })

    return createOrderResult.data.createCreditOrder
  }

  const completeOrder = (creditOrderResponse: CreditOrderResponse) => {
    shouldShowSpinner(false)

    window.location.href = creditOrderResponse.paymentRedirectUrl
  }

  const startCheckout = async () => {
    clearErrors()
    const validationResult = validateCheckoutForm(formData)

    if (!validationResult.valid) {
      setValidationErrors(validationResult.validationErrors)
      return
    }

    const { firstName, lastName, email, phone } = validationResult.formData!

    shouldShowSpinner(true)

    try {
      const result = await updateUserWithLoginMutation({
        variables: {
          firstName,
          lastName,
          email,
          phone: { countryCode: phone.countryCode, number: phone.number },
          marketingOptIn: false,
        },
      })

      const updatedUser = result?.data?.updateUserWithLogin
      setUser(updatedUser)

      completeOrder(await createOrder())
    } catch (error: any) {
      handleCheckoutError({ apolloError: error })
    }
  }

  if (!bundle) {
    return <Navigate to={CREDITS_PATH} replace={true} />
  }

  return (
    <PageLayout>
      <CheckoutLayout
        title={t('title.checkout')}
        onBack={close}
        left={{
          hidden: isMobile,
          title: t('basket.yourBasket'),
          content: (
            <div className="mb-3 flex gap-2 border-b border-gray-300 pb-2">
              <div className="flex h-[72px] min-w-[72px] items-center justify-center bg-[#F6F6F7] text-center text-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline h-12 w-12"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
                  />
                </svg>
              </div>
              <div className="w-full">
                <div className="flex flex-col">
                  <div className="flex justify-between">
                    <div className="flex flex-1 flex-col font-semibold">
                      <div className="line-clamp-2">{bundle.name}</div>
                    </div>
                    <div className="flex min-w-20 flex-col text-right font-semibold">
                      <div>
                        {formatMoney(bundle.price, i18n.resolvedLanguage)}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <div className="flex items-center text-sm font-normal">
                      {t('credits.totalCredits', {
                        count: bundle.totalCredits,
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ),
        }}
        right={{
          title: t('checkout.yourInformation'),
          content: (
            <>
              <CheckoutForm
                formData={formData}
                validationErrors={validationErrors}
                setFormData={setFormData}
              />

              <div className="text-md mt-4 flex justify-between font-semibold uppercase md:hidden">
                <div>{t('total')}</div>
                <div>{formatMoney(bundle.price, i18n.resolvedLanguage)}</div>
              </div>

              <div className="mt-4">
                <div className="mb-2 mt-10 text-center text-sm">
                  <Trans
                    i18nKey="checkout.terms"
                    components={{
                      agreementText: (
                        <span className="font-medium">
                          {t('button.agreeAndContinue')}
                        </span>
                      ),
                      privacyLink: (
                        <Link
                          to="https://wearebookable.com/privacy"
                          target="_blank"
                          rel="noreferrer"
                          className="underline"
                        >
                          {t('privacyPolicy')}
                        </Link>
                      ),
                      termsLink: (
                        <Link
                          to="https://wearebookable.com/terms"
                          target="_blank"
                          rel="noreferrer"
                          className="underline"
                        >
                          {t('termsOfService')}
                        </Link>
                      ),
                    }}
                  />
                </div>

                <button onClick={startCheckout} className="button-primary">
                  {t('button.agreeAndContinue')}
                </button>
              </div>

              <div className="mt-4">
                <ErrorMessage
                  visible={errorMessage !== null}
                  message={errorMessage}
                />
              </div>
            </>
          ),
        }}
      />

      <OverlaySpinner
        visible={showSpinner}
        title={t('spinner.titleInProgress', {
          action: t('checkout'),
        })}
        subtitle={t('spinner.subtitlePleaseWait')}
      />
    </PageLayout>
  )
}

export default CreditCheckout
