import { useTranslation } from 'react-i18next'
import { Event, User } from 'generated/graphql'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { formatDate } from 'lib/dateUtils'
import { eventImageSort } from 'shared/helpers/event-image-sort'
import { DurationTag } from './Duration'
import PriceText from './PriceText'

const EventImage = ({
  isMobile,
  imageSrc,
  event,
  user,
}: {
  isMobile: boolean
  imageSrc: string
  event: Event
  user: User | false
}) => {
  return (
    <div className="flex flex-col items-center">
      <img
        src={imageSrc}
        alt="Event"
        className={`inline-block ${
          !isMobile ? 'h-44 w-44 rounded-lg' : 'h-24 w-24'
        }`}
      />
      {isMobile && (
        <div
          className={`w-full bg-primary py-0.5 text-center text-sm text-white ${
            event.price.amount === 0 ? 'uppercase' : 'capitalize'
          }`}
        >
          <PriceText event={event} user={user} />
        </div>
      )}
    </div>
  )
}

interface EventProps {
  event: Event
  user: User | false
  selected: (event: Event) => void
}

const EventTile = ({ event, user, selected }: EventProps) => {
  const { i18n } = useTranslation()
  const { isMobile } = useWindowDimensions()
  const imageSrc =
    event.images.length > 0
      ? event.images.slice().sort(eventImageSort)[0].url
      : import.meta.env.VITE_APP_EVENT_IMAGE_DEFAULT
  const freeEvent = event.price.amount === 0

  return (
    <div className="relative w-full">
      <button
        className="flex w-full border-b border-gray-300 p-2 text-left md:py-4"
        onClick={() => selected(event)}
        data-id={event.id}
        data-testid={`${freeEvent ? 'free' : 'paid'}-event`}
      >
        <div className="hidden md:mr-3 md:block">
          <EventImage
            imageSrc={imageSrc}
            isMobile={isMobile}
            event={event}
            user={user}
          />
        </div>

        <div className="mr-2 flex flex-1 flex-col md:h-44">
          <div className="text-sm uppercase md:text-base">
            {formatDate({
              date: new Date(event.startTime),
              format: 'full',
              timeZone: event.timeZone,
              locale: i18n.resolvedLanguage,
            })}
          </div>
          <div className="line-clamp-2 font-semibold md:line-clamp-1 md:text-lg">
            {event.name}
          </div>
          {!isMobile && event.description && (
            <div className="mt-1 text-sm font-light md:line-clamp-4">
              {event.description}
            </div>
          )}

          <div className="absolute bottom-4">
            <DurationTag event={event} />
          </div>

          <div className="hidden flex-grow justify-end md:flex">
            <div className="self-end">
              <div
                className={`font-bold text-primary ${
                  freeEvent ? 'uppercase' : 'capitalize'
                }`}
              >
                <PriceText event={event} user={user} />
              </div>
            </div>
          </div>
        </div>
        <div className="md:hidden">
          <EventImage
            imageSrc={imageSrc}
            isMobile={isMobile}
            event={event}
            user={user}
          />
        </div>
      </button>
    </div>
  )
}

export default EventTile
