import { useTranslation } from 'react-i18next'
import { AuthProvider } from 'lib/auth'

interface LoginProviderButtonProps {
  provider: AuthProvider
  onClick: (provider: AuthProvider) => void
}

const providerSvg = (provider: AuthProvider) => {
  switch (provider) {
    case AuthProvider.Apple:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 170 170"
          className="fill-black group-hover:fill-white"
        >
          <path d="M150.37 130.25c-2.45 5.66-5.35 10.87-8.71 15.66-4.58 6.53-8.33 11.05-11.22 13.56-4.48 4.12-9.28 6.23-14.42 6.35-3.69 0-8.14-1.05-13.32-3.18-5.197-2.12-9.973-3.17-14.34-3.17-4.58 0-9.492 1.05-14.746 3.17-5.262 2.13-9.501 3.24-12.742 3.35-4.929.21-9.842-1.96-14.746-6.52-3.13-2.73-7.045-7.41-11.735-14.04-5.032-7.08-9.169-15.29-12.41-24.65-3.471-10.11-5.211-19.9-5.211-29.378 0-10.857 2.346-20.221 7.045-28.068 3.693-6.303 8.606-11.275 14.755-14.925s12.793-5.51 19.948-5.629c3.915 0 9.049 1.211 15.429 3.591 6.362 2.388 10.447 3.599 12.238 3.599 1.339 0 5.877-1.416 13.57-4.239 7.275-2.618 13.415-3.702 18.445-3.275 13.63 1.1 23.87 6.473 30.68 16.153-12.19 7.386-18.22 17.731-18.1 31.002.11 10.337 3.86 18.939 11.23 25.769 3.34 3.17 7.07 5.62 11.22 7.36-.9 2.61-1.85 5.11-2.86 7.51zM119.11 7.24c0 8.102-2.96 15.667-8.86 22.669-7.12 8.324-15.732 13.134-25.071 12.375a25.222 25.222 0 0 1-.188-3.07c0-7.778 3.386-16.102 9.399-22.908 3.002-3.446 6.82-6.311 11.45-8.597 4.62-2.252 8.99-3.497 13.1-3.71.12 1.083.17 2.166.17 3.24z" />
        </svg>
      )
    // case AuthProvider.Facebook:
    //   return (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="20"
    //       height="20"
    //       viewBox="0 0 20 20"
    //       className="fill-[#4267B2] group-hover:fill-white"
    //     >
    //       <path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />
    //     </svg>
    //   );
    case AuthProvider.Google:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1556 1587"
          width="16"
          height="16"
        >
          <g id="Layer">
            <path
              id="Layer"
              className="fill-[#4285f4] group-hover:fill-white"
              d="M1555.26 810.89C1555.26 745.67 1549.96 698.07 1538.51 648.72L793.5 648.72L793.5 943.1L1230.8 943.1C1221.99 1016.26 1174.38 1126.44 1068.58 1200.47L1067.09 1210.33L1302.65 1392.76L1318.97 1394.39C1468.85 1256 1555.26 1052.4 1555.26 810.89Z"
            />
            <path
              id="Layer"
              className="fill-[#34a853] group-hover:fill-white"
              d="M793.5 1586.53C1007.74 1586.53 1187.6 1516.02 1318.97 1394.39L1068.58 1200.47C1001.57 1247.19 911.64 1279.8 793.5 1279.8C583.66 1279.8 405.57 1141.42 342.08 950.16L332.78 950.95L87.84 1140.45L84.64 1149.35C215.12 1408.48 483.15 1586.53 793.5 1586.53Z"
            />
            <path
              id="Layer"
              className="fill-[#fbbc05] group-hover:fill-white"
              d="M342.08 950.16C325.33 900.8 315.64 847.91 315.64 793.27C315.64 738.62 325.33 685.73 341.2 636.37L340.76 625.86L92.75 433.32L84.64 437.17C30.86 544.71 0 665.46 0 793.27C0 921.07 30.86 1041.82 84.64 1149.35L342.08 950.16Z"
            />
            <path
              id="Layer"
              className="fill-[#eb4335] group-hover:fill-white"
              d="M793.5 306.73C942.5 306.73 1043.01 371.07 1100.32 424.84L1324.26 206.25C1186.72 78.45 1007.74 0 793.5 0C483.15 0 215.12 178.04 84.64 437.17L341.2 636.37C405.57 445.11 583.66 306.73 793.5 306.73Z"
            />
          </g>
        </svg>
      )
    default:
      return null
  }
}

const LoginProviderButton = ({
  provider,
  onClick,
}: LoginProviderButtonProps) => {
  const { t } = useTranslation()
  const buttonLogo = providerSvg(provider)

  return (
    <div className="relative">
      <button
        onClick={() => onClick(provider)}
        autoFocus={false}
        className="transparent group flex w-full items-center justify-center rounded-lg bg-gray-100 px-4 py-2 text-base hover:border-primary hover:bg-primary hover:text-white"
      >
        <div className="mr-2">{buttonLogo && buttonLogo}</div>
        <div className="">{t('button.login.provider', { provider })}</div>
      </button>
    </div>
  )
}
export default LoginProviderButton
