import { CreditBundle, MembershipPlan } from 'generated/graphql'
import { defaultPriceForPlan } from 'shared/helpers/memberships'

export const creditBundleInputFromCreditBundle = (
  creditBundle: CreditBundle
) => {
  return (({ id, totalCredits, creditValidityMonths, price }) => ({
    id,
    totalCredits,
    creditValidityMonths,
    priceCents: Math.round(price.amount * 100),
  }))(creditBundle)
}

export const membershipPlanInputFromMembershipPlan = (
  membershipPlan: MembershipPlan
) => {
  const planPrice = defaultPriceForPlan(membershipPlan)

  return (({ id, bookingAllowance }) => ({
    id,
    defaultPriceId: planPrice!.id,
    bookingAllowance,
  }))(membershipPlan)
}
