import { useTranslation } from 'react-i18next'
import { UserBooking } from 'generated/graphql'
import { formatDate } from 'lib/dateUtils'
import { RecurringEventType } from 'lib/eventUtils'

interface BookingProps {
  booking: UserBooking
  cancelBooking?: (booking: UserBooking) => void
}

const eventStartTime = (booking: UserBooking) => {
  return new Date(
    booking.event.type === RecurringEventType
      ? booking.recurringEventInstance
      : booking.event.startTime
  )
}

const canCancel = (booking: UserBooking) => {
  const timeNow = new Date().getTime()

  return (
    booking.cancellationPeriod > 0 &&
    timeNow <
      eventStartTime(booking).getTime() -
        booking.cancellationPeriod * 60 * 60 * 1000
  )
}

const BookedEvent = ({ booking, cancelBooking }: BookingProps) => {
  const { t, i18n } = useTranslation()
  const imageSrc =
    booking.event.images.length > 0
      ? booking.event.images[0].url
      : import.meta.env.VITE_APP_EVENT_IMAGE_DEFAULT

  return (
    <div className="flex" data-testid={booking.id}>
      <div className="bg-red flex-none p-2">
        <img src={imageSrc} alt="Event" className="inline-block h-16 w-16" />
      </div>
      <div className="grow p-2 text-left">
        <div className="mr-2 flex flex-1 flex-col">
          <div className="text-sm uppercase">
            {formatDate({
              date: eventStartTime(booking),
              format: 'full',
              timeZone: booking.event.timeZone,
              locale: i18n.resolvedLanguage,
            })}{' '}
          </div>
          <div className="line-clamp-1 font-semibold">{booking.event.name}</div>
          {booking.quantity > 1 && (
            <div className="text-sm text-primary">
              {t('bookings.places', { count: booking.quantity })}
            </div>
          )}
        </div>
      </div>

      <div className="w-24 flex-none p-2">
        <div className="flex h-full items-center">
          {booking.cancelled && (
            <div className="text-center text-sm text-gray-500">
              Booking Cancelled
            </div>
          )}
          {!booking.cancelled && cancelBooking && canCancel(booking) && (
            <button
              onClick={() => cancelBooking(booking)}
              className="button-primary-outline-sm"
              data-bookingid={booking.id}
            >
              {t('button.cancelBooking')}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default BookedEvent
