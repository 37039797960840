import clsx from 'clsx'
import { ReactNode } from 'react'

import './SwiperItem.css'

const Caret = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      className="size-8"
    >
      <g>
        <path
          d="M24,0C10.745,0,0,10.745,0,24c0,13.254,10.745,24,24,24s24-10.746,24-24C48,10.745,37.255,0,24,0z M35.993,19.071L24.516,30.55l-0.006-0.005l-0.513,0.514l-0.007-0.008l-0.007,0.008l-1.151-1.15l0.007-0.008L12.008,19.068   l0.002-0.002l-0.003-0.003l2.12-2.121l9.877,9.876l9.869-9.868l1.057,1.056l0.004-0.004l1.061,1.06l-0.005,0.006L35.993,19.071z"
          fill="#D3D3D3E6"
        />
      </g>
    </svg>
  )
}

function SwiperItem({
  item,
  canSwipeLeft,
  canSwipeRight,
  swipeLeft,
  swipeRight,
}: {
  item: ReactNode
  canSwipeLeft: boolean
  canSwipeRight: boolean
  swipeLeft: () => void
  swipeRight: () => void
}) {
  const navButtonClasses = 'absolute top-1/2 flex items-center justify-center'

  return (
    <li className="swiper-item relative">
      <>
        {item}
        <button
          key="previous"
          className={`${navButtonClasses} left-0 ml-2 rotate-90 ${clsx(!canSwipeLeft && 'hidden')}`}
          onClick={swipeLeft}
        >
          <Caret />
        </button>
        <button
          key="next"
          className={`${navButtonClasses} right-0 mr-2 -rotate-90 ${clsx(!canSwipeRight && 'hidden')}`}
          onClick={swipeRight}
        >
          <Caret />
        </button>
      </>
    </li>
  )
}

export default SwiperItem
