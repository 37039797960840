import { createContext } from 'react'
import { CheckoutProps } from 'shared/interface/checkout'

export const CheckoutContext = createContext(
  {} as {
    checkout: CheckoutProps
    setCheckout: (checkout: CheckoutProps) => void
    setCheckoutOrderId: (orderId: string | null) => void
  }
)
