import { useMemo } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { MEMBERSHIP_CONFIRMATION_PATH, MEMBERSHIPS_PATH } from 'Routes'

const CheckoutSuccess = () => {
  function useQueryString() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
  }

  const query = useQueryString()
  const membershipId = query.get('m')

  return membershipId ? (
    <Navigate
      to={MEMBERSHIP_CONFIRMATION_PATH}
      replace={true}
      state={{ membershipId }}
    />
  ) : (
    <Navigate to={MEMBERSHIPS_PATH} replace={true} />
  )
}

export default CheckoutSuccess
