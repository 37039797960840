import { createContext } from 'react'
import { Money } from 'generated/graphql'
import {
  CartItem,
  CreditRedemption,
  MembershipBooking,
} from 'shared/constants/cart'

interface ICartContextState {
  cartItems: Array<CartItem>
  cartTotal: Money | 0
  creditRedemptions: Array<CreditRedemption>
  membershipBookings: Array<MembershipBooking>
  membershipQuotaExceeded: boolean
  freeTrialRedemption: string | null
}

const initialState = {
  cartItems: [],
  cartTotal: 0 as Money | 0,
  creditRedemptions: [],
  membershipBookings: [],
  membershipQuotaExceeded: false,
  freeTrialRedemption: null,
}

export const CartContext = createContext<{
  cart: ICartContextState
  addToCart: (item: CartItem) => void
  removeFromCart: (item: CartItem) => void
  emptyCart: () => void
  applyTrialPass: (redemptionCode: string | null) => void
}>({
  cart: initialState,
  addToCart: () => {},
  removeFromCart: () => {},
  emptyCart: () => {},
  applyTrialPass: () => {},
})
