import { useTranslation } from 'react-i18next'
import { Event } from 'generated/graphql'
import { eventDuration } from 'lib/eventUtils'

const DEFAULT_FORMAT = 'full'
const validFormats = ['abbreviated', DEFAULT_FORMAT]

export const DurationText = ({
  event,
  format,
}: {
  event: Event
  format?: string
}) => {
  const { t } = useTranslation()
  const duration = eventDuration(event)
  const durationStringSuffix =
    format && validFormats.includes(format) ? format : 'full'
  let text = ''

  if (duration.hours > 0) {
    text += t(`time.hours_${durationStringSuffix}`, { count: duration.hours })
  }

  if (duration.minutes > 0) {
    if (duration.hours > 0) {
      text += ' '
    }
    text += t(`time.minutes_${durationStringSuffix}`, {
      count: duration.minutes,
    })
  }

  return <>{text}</>
}

export const DurationTag = ({
  event,
  format,
}: {
  event: Event
  format?: string
}) => {
  const { t } = useTranslation()
  const duration = eventDuration(event)
  const durationStringSuffix =
    format && validFormats.includes(format) ? format : 'full'
  let text = ''

  if (duration.hours > 0) {
    text += t(`time.hours_${durationStringSuffix}`, { count: duration.hours })
  }

  if (duration.minutes > 0) {
    if (duration.hours > 0) {
      text += ' '
    }
    text += t(`time.minutes_${durationStringSuffix}`, {
      count: duration.minutes,
    })
  }

  return (
    <div className="rounded-full bg-gray-200 px-3 py-1 text-sm">{text}</div>
  )
}
