import { useEffect, useState, ReactNode } from 'react'
import { Money } from 'generated/graphql'
import { CHECKOUT_LOCAL_STORAGE_KEY } from 'localStorage'
import { CheckoutContext } from './CheckoutContext'

interface CheckoutProps {
  total: Money | 0
  orderId?: string | null
}

const localStorageCheckout = (): CheckoutProps => {
  const persistedCheckout: CheckoutProps = JSON.parse(
    localStorage.getItem(CHECKOUT_LOCAL_STORAGE_KEY) as string
  )

  return {
    total: persistedCheckout ? persistedCheckout.total : 0,
    orderId: persistedCheckout ? persistedCheckout.orderId : null,
  }
}

const CheckoutProvider = ({ children }: { children: ReactNode }) => {
  const [checkout, setCheckout] = useState(localStorageCheckout())

  const setCheckoutOrderId = (orderId: string | null) => {
    setCheckout({ ...checkout, orderId })
  }

  useEffect(() => {
    localStorage.setItem(CHECKOUT_LOCAL_STORAGE_KEY, JSON.stringify(checkout))
  }, [checkout])

  return (
    <CheckoutContext.Provider
      value={{
        checkout,
        setCheckout,
        setCheckoutOrderId,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}

export { CheckoutProvider, CheckoutContext }
