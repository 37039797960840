import { useTranslation } from 'react-i18next'

interface QuantityPickerProps {
  quantity: number
  maxQuantity: number
  incrementQuantity: () => void
  decrementQuantity: () => void
}

const QuantityPicker = ({
  quantity,
  maxQuantity,
  incrementQuantity,
  decrementQuantity,
}: QuantityPickerProps) => {
  const { t } = useTranslation()

  return (
    <div
      className={`flex select-none items-center ${
        maxQuantity < 2 ? 'hidden' : ''
      }`}
    >
      <div className="font-semibold">{`${t('quantity')}:`}</div>
      <div className="flex items-center">
        <div className="ml-1 mr-2 block min-w-[20px] font-medium">
          {quantity}
        </div>
        <button
          type="button"
          className={`mr-3 rounded-full border p-1 ${
            quantity === 1
              ? 'border-gray-400 text-gray-400'
              : 'border-primary text-primary'
          }`}
          onClick={decrementQuantity}
        >
          <svg
            className="h-4 w-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M20 12H4"
            ></path>
          </svg>
        </button>
        <button
          type="button"
          className={`rounded-full border p-1 ${
            quantity === maxQuantity
              ? 'border-gray-400 text-gray-400'
              : 'border-primary text-primary'
          }`}
          onClick={incrementQuantity}
        >
          <svg
            className="h-4 w-4"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  )
}

export default QuantityPicker
