interface LoginOverlayProps {
  visible: boolean
  title: string
  subtitle: string
  onClick: () => void
}

const LoginOverlay = ({
  visible,
  title,
  subtitle,
  onClick,
}: LoginOverlayProps) => {
  if (!visible) {
    return null
  }
  return (
    <div className="fixed bottom-0 left-0 right-0 top-0 z-50 flex h-screen w-full flex-col items-center justify-center overflow-hidden bg-gray-700 opacity-90">
      <h2 className="mb-2 text-center text-xl font-semibold text-white">
        {title}
      </h2>
      <button
        className="px-12 text-center text-white underline underline-offset-4"
        onClick={onClick}
      >
        {subtitle}
      </button>
    </div>
  )
}

export default LoginOverlay
