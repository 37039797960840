import { useTranslation } from 'react-i18next'

export const AccountIdError = () => {
  const { t } = useTranslation()
  return (
    <div className="p-6 text-center md:min-h-screen">
      <div className="">
        <div className="mb-6 text-2xl font-bold">
          {t('businessError.headline')}
        </div>
        <div>{t('businessError.message')}</div>
      </div>
      <img
        src="https://bookable.ams3.cdn.digitaloceanspaces.com/brand/logo.png"
        alt="bookable"
        className="mt-8 inline-block h-5"
      />
    </div>
  )
}

export const ServerError = () => {
  const { t } = useTranslation()

  return (
    <div className="h-screen">
      <div className="flex h-full flex-col justify-center">
        <div className="flex flex-col items-center">
          <div className="text-lg">{t('error.generic')}</div>
          <div className="mt-4">
            <button
              onClick={() => window.location.reload()}
              className="button-primary"
            >
              {t('retry')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
