import { useMutation, ApolloError } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useState, useEffect, ChangeEvent } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import {
  BOOKING_CHECKOUT_SUCCESS_PATH,
  BOOKING_CHECKOUT_CANCEL_PATH,
  IFRAME_CHECKOUT_RETURN_PATH,
  BOOKING_CONFIRMATION_PATH,
  IFRAME_CHECKOUT_PATH,
  CART_PATH,
  LOGIN_PATH,
} from 'Routes'
import { useBusiness } from 'business/use-business'
import { useCart } from 'cart/use-cart'
import { useCheckout } from 'checkout/use-checkout'
import {
  Business,
  BookingOrderResponse,
  EventBookingInput,
  User,
  CheckoutUiMode,
} from 'generated/graphql'
import { CREATE_BOOKING_ORDER_MUTATION } from 'graphql/BookingOrder'
import { VALIDATE_TRIAL_PASS_REDEMPTION_CODE_MUTATION } from 'graphql/TrialPass'
import {
  CREATE_USER_WITHOUT_LOGIN_MUTATION,
  UPDATE_USER_WITH_LOGIN_MUTATION,
} from 'graphql/User'
import {
  ERROR_EVENT_OVER_CAPACITY,
  ERROR_EVENT_CANCELLED,
  RECURRING_EVENT_UNAVAILABLE,
  ERROR_PAYMENT_UNAVAILABLE,
  ERROR_TRIAL_PASS_ALREADY_REDEEMED,
  ERROR_TRIAL_PASS_NOT_ALLOWED,
  ERROR_TRIAL_PASS_REDEMPTION_CODE_INVALID,
  ERROR_TRIAL_PASS_NOT_ENABLED,
  ERROR_TRIAL_PASS_REDEMPTION_RATE_LIMIT,
} from 'graphql/errors'
import useWindowDimensions from 'hooks/useWindowDimensions'
import { formatDate } from 'lib/dateUtils'
import { formatMoney } from 'lib/moneyUtils'
import {
  COOKIE_CONSENT_LOCAL_STORAGE_KEY,
  COOKIE_CONSENT_TRUE,
} from 'localStorage'
import { useLogin } from 'login/use-login'
import TextInput from 'pages/form/TextInput'
import CheckoutLayout from 'shared/components/CheckoutLayout'
import ErrorMessage from 'shared/components/ErrorMessage'
import OverlaySpinner from 'shared/components/OverlaySpinner'
import PageLayout from 'shared/components/PageLayout'
import { CartItem } from 'shared/constants/cart'
import {
  initialCheckoutFormData,
  validateCheckoutForm,
} from 'shared/helpers/checkout'
import { isInFrame } from 'shared/helpers/iframe'
import { useGuestCookie } from 'shared/hooks/use-guest-cookie'
import {
  CheckoutFormData,
  CheckoutFormValidationErrors,
} from 'shared/interface/checkout'
import { CartItems } from './Cart'
import CheckoutForm from './CheckoutForm'

interface UserInput {
  firstName: string
  lastName: string
  email: string
  phone: {
    countryCode: string
    number: string
  }
  marketingOptIn: boolean
}

const checkoutUrls = (isInFrame: boolean) => {
  if (isInFrame) {
    return {
      success: null,
      cancel: null,
      return: window.location.origin + IFRAME_CHECKOUT_RETURN_PATH,
    }
  }

  return {
    success: window.location.origin + BOOKING_CHECKOUT_SUCCESS_PATH,
    cancel: window.location.origin + BOOKING_CHECKOUT_CANCEL_PATH,
    return: null,
  }
}

const TrialPassRedemption = ({
  user,
  cartItems,
  redemptionCode,
  redemptionCodeChanged,
}: {
  user: User | false
  cartItems: Array<CartItem>
  redemptionCode: string
  redemptionCodeChanged: (e: ChangeEvent<HTMLInputElement>) => void
}) => {
  const { t } = useTranslation()
  const { applyTrialPass } = useCart()
  const [expanded, setExpanded] = useState(false)
  const [valid, setValid] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [validateTrialPassRedemptionCoderMutation, { loading }] = useMutation(
    VALIDATE_TRIAL_PASS_REDEMPTION_CODE_MUTATION
  )

  const validateRedemptionCode = async () => {
    const trialPassEligibleItems = cartItems.filter((item) => {
      return item.trialPassEligible
    })

    if (error) {
      setError(null)
    }

    if (valid) {
      setValid(false)
    }

    if (!redemptionCode) {
      return
    }

    if (trialPassEligibleItems.length === 0) {
      setError(t('error.noItemsEligibleForTrialPass'))
      applyTrialPass(null)
      return
    }

    try {
      const result = await validateTrialPassRedemptionCoderMutation({
        variables: {
          redemptionCode,
        },
      })

      if (result.data.validateTrialPassRedemptionCode.valid) {
        setValid(true)
        applyTrialPass(redemptionCode)
      } else {
        applyTrialPass(null)
      }
    } catch (error: any) {
      let errorMessage = t('error.generic')

      if (
        error &&
        error.graphQLErrors.length > 0 &&
        error.graphQLErrors[0].extensions
      ) {
        console.log(error.graphQLErrors[0].extensions)

        const errorCode = error.graphQLErrors[0].extensions.code
        switch (errorCode) {
          case ERROR_TRIAL_PASS_ALREADY_REDEEMED:
            errorMessage = t('error.trialPassNotAllowed')
            break
          case ERROR_TRIAL_PASS_NOT_ALLOWED:
            errorMessage = t('error.trialPassNotAllowed')
            break
          case ERROR_TRIAL_PASS_REDEMPTION_CODE_INVALID:
            errorMessage = t('error.trialPassRedemptionCodeInvalid')
            break
          case ERROR_TRIAL_PASS_NOT_ENABLED:
            errorMessage = t('error.trialPassNotEnabled')
            break
          case ERROR_TRIAL_PASS_REDEMPTION_RATE_LIMIT:
            errorMessage = t('error.trialPassRateLimit')
            break
          default:
            Sentry.captureException(error)
        }
      }

      setError(errorMessage)
      applyTrialPass(null)
    }
  }

  if (user && !user.trialPassEligible) {
    return null
  }

  return (
    <div className="mt-4">
      <details className="group peer">
        <summary
          className="flex cursor-pointer justify-between"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="text-left text-sm text-primary">
            {t('checkout.trialPassPrompt')}
          </div>
          <div className="text-primary">
            <svg
              className="inline-block h-6 w-6 transition-transform group-open:rotate-180"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            >
              <polyline points="6 9 12 15 18 9"></polyline>
            </svg>
          </div>
        </summary>
      </details>
      {expanded &&
        (user ? (
          <div className="mb-6 mt-2 flex gap-6">
            <div className="relative">
              <TextInput
                name="trialPassRedemptionCode"
                value={redemptionCode}
                autoCapitalize="off"
                placeholder={t('checkout.trialPassRedemptionCode')}
                onChange={redemptionCodeChanged}
                error={error}
                rightIcon={
                  valid ? (
                    <div className="text-[#16A34A]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                        />
                      </svg>
                    </div>
                  ) : undefined
                }
              />
            </div>

            <button
              className="button-primary h-10 w-32 self-start text-base"
              onClick={validateRedemptionCode}
            >
              {loading ? (
                <div className="flex justify-center">
                  <svg
                    className="animate-spin text-white"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                  >
                    <path
                      d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                      stroke="currentColor"
                      strokeWidth="5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                    <path
                      d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                      stroke="currentColor"
                      strokeWidth="5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="text-primary"
                    ></path>
                  </svg>
                </div>
              ) : (
                t('apply')
              )}
            </button>
          </div>
        ) : (
          t('checkout.loginRequiredForTrialPass')
        ))}
    </div>
  )
}

interface LocationState {
  paymentFailed: string
}

const Checkout = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const state = (location.state as LocationState) || {}
  const { paymentFailed } = state
  const { isMobile } = useWindowDimensions()
  const { checkout, setCheckoutOrderId } = useCheckout()
  const { business } = useBusiness()
  const { user, setUser } = useLogin()
  const { cookiedGuest, setGuestCookie } = useGuestCookie()
  const cookieConsent =
    localStorage.getItem(COOKIE_CONSENT_LOCAL_STORAGE_KEY) ===
    COOKIE_CONSENT_TRUE
  const [formData, setFormData] = useState<CheckoutFormData>({
    firstName: '',
    lastName: '',
    email: '',
    phoneCountryCode: '',
    phoneNumber: '',
  })
  const [validationErrors, setValidationErrors] =
    useState<CheckoutFormValidationErrors>({
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
    })
  const [trialPassRedemptionCode, setTrialPassRedemptionCode] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | null>(
    paymentFailed ? t('paymentFailed') : null
  )
  const [showSpinner, shouldShowSpinner] = useState(false)
  const { cart, emptyCart } = useCart()
  const {
    cartItems,
    cartTotal,
    creditRedemptions,
    membershipBookings,
    freeTrialRedemption,
  } = cart
  const [events, setEvents] = useState<EventBookingInput[]>([])
  const [createUserWithoutLoginMutation] = useMutation(
    CREATE_USER_WITHOUT_LOGIN_MUTATION
  )
  const [updateUserWithLoginMutation] = useMutation(
    UPDATE_USER_WITH_LOGIN_MUTATION
  )
  const [createOrderMutation] = useMutation(CREATE_BOOKING_ORDER_MUTATION)

  const close = () => {
    navigate(CART_PATH, {
      replace: true,
    })
  }

  useEffect(() => {
    if (checkout.orderId) {
      const cancelPath = `${BOOKING_CHECKOUT_CANCEL_PATH}?o=${checkout.orderId}`
      setCheckoutOrderId(null)
      navigate(cancelPath, {
        replace: true,
      })

      return
    } else {
      setCheckoutOrderId(null)
    }

    if (!checkout.total || cartItems.length === 0) {
      close()
    }

    setFormData(
      initialCheckoutFormData({
        user,
        business: business as Business,
        cookieConsent,
        cookiedGuest,
      })
    )
  }, [cookiedGuest, user]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setEvents(
      cartItems.map((item: CartItem) => {
        return {
          eventId: item.eventId,
          startTime: item.startTime,
          quantity: item.quantity,
        }
      })
    )
  }, [cartItems])

  const getCartItemByEventId = (id: string, startTime?: string) => {
    return startTime
      ? cartItems.find(
          (item) => item.eventId === id && item.startTime === startTime
        )
      : cartItems.find((item) => item.eventId === id)
  }

  const createUserWithoutLogin = async (userInfo: UserInput) => {
    const result = await createUserWithoutLoginMutation({
      variables: {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        phone: userInfo.phone,
        marketingOptIn: userInfo.marketingOptIn,
      },
    })

    const user = result?.data?.createUserWithoutLogin

    if (cookieConsent) {
      setGuestCookie({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneCountryCode: user.phoneCountryCode,
        phone: user.phone,
      })
    }

    return user
  }

  const updateUserWithLogin = async (userInfo: UserInput) => {
    const result = await updateUserWithLoginMutation({
      variables: {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: userInfo.email,
        phone: userInfo.phone,
        marketingOptIn: userInfo.marketingOptIn,
      },
    })

    const user = result?.data?.updateUserWithLogin
    setUser(user)

    return user
  }

  const createOrder = async ({ userId }: { userId?: string }) => {
    const createOrderResult = await createOrderMutation({
      variables: {
        userId,
        events,
        uiMode: isInFrame ? CheckoutUiMode.Iframe : CheckoutUiMode.Browser,
        checkoutUrls: checkoutUrls(isInFrame),
        trialPassRedemptionCode: trialPassRedemptionCode || undefined,
      },
    })

    return createOrderResult.data.createBookingOrder
  }

  const completeOrder = async (bookingOrderResponse: BookingOrderResponse) => {
    shouldShowSpinner(false)

    const {
      bookingOrder,
      paymentRedirectUrl,
      paymentInstructions,
      stripeClientSecret,
      stripeAccountId,
    } = bookingOrderResponse

    if (paymentRedirectUrl) {
      setCheckoutOrderId(bookingOrder.id)
      window.location.href = paymentRedirectUrl
    } else if (stripeClientSecret) {
      navigate(IFRAME_CHECKOUT_PATH, {
        state: {
          stripeClientSecret,
          stripeKey: import.meta.env[
            stripeClientSecret.startsWith('cs_test')
              ? 'VITE_APP_STRIPE_PUBLISHABLE_KEY_TEST'
              : 'VITE_APP_STRIPE_PUBLISHABLE_KEY'
          ]!,
          stripeAccountId,
        },
      })
    } else if (bookingOrder.reference) {
      emptyCart()
      navigate(BOOKING_CONFIRMATION_PATH, {
        replace: true,
        state: {
          orderReference: bookingOrder.reference,
          paymentInstructions,
        },
      })
    } else {
      handleCheckoutError({})
    }
  }

  const handleCheckoutError = ({
    apolloError,
    message,
  }: {
    apolloError?: ApolloError
    message?: string
  }) => {
    let errorMessage = message ?? t('error.generic')

    if (apolloError && apolloError.message === 'Unauthorized.') {
      navigate(LOGIN_PATH, {
        replace: true,
        state: {
          referrerPath: location.pathname,
          sessionExpired: true,
        },
      })
      return
    }

    if (
      apolloError &&
      apolloError?.graphQLErrors.length > 0 &&
      apolloError.graphQLErrors[0].extensions
    ) {
      console.error('graphQLError', apolloError.graphQLErrors[0].extensions)

      const errorCode = apolloError.graphQLErrors[0].extensions.code
      switch (errorCode) {
        case ERROR_PAYMENT_UNAVAILABLE:
          errorMessage = t('error.paymentUnavailableForBookings')
          break
        case ERROR_EVENT_OVER_CAPACITY:
          {
            const overCapacityItem = getCartItemByEventId(
              apolloError.graphQLErrors[0].extensions.event_id as string
            )
            errorMessage = t('error.capacity', {
              eventName: overCapacityItem!.name,
            })
          }
          break
        case ERROR_EVENT_CANCELLED:
          {
            const cancelledItem = getCartItemByEventId(
              apolloError.graphQLErrors[0].extensions.event_id as string
            )
            errorMessage = t('error.cancelled', {
              eventName: cancelledItem!.name,
            })
          }
          break
        case RECURRING_EVENT_UNAVAILABLE:
          {
            const unavailableItem = getCartItemByEventId(
              apolloError.graphQLErrors[0].extensions.event_id as string,
              apolloError.graphQLErrors[0].extensions.instance as string
            )
            errorMessage = t('error.recurringEventUnavailable', {
              eventName: unavailableItem!.name,
              startTime: formatDate({
                date: new Date(unavailableItem!.startTime),
                format: 'short',
                timeZone: unavailableItem!.timeZone,
                locale: i18n.resolvedLanguage,
              }),
            })
          }
          break
        case ERROR_TRIAL_PASS_ALREADY_REDEEMED:
          errorMessage = t('error.trialPassNotAllowed')
          break
        case ERROR_TRIAL_PASS_NOT_ALLOWED:
          errorMessage = t('error.trialPassNotAllowed')
          break
        case ERROR_TRIAL_PASS_REDEMPTION_CODE_INVALID:
          errorMessage = t('error.trialPassRedemptionCodeInvalid')
          break
        case ERROR_TRIAL_PASS_NOT_ENABLED:
          errorMessage = t('error.trialPassNotEnabled')
          break
        case ERROR_TRIAL_PASS_REDEMPTION_RATE_LIMIT:
          errorMessage = t('error.trialPassRateLimit')
          break
        default:
          Sentry.captureException(apolloError)
      }
    }

    shouldShowSpinner(false)
    setErrorMessage(errorMessage)
  }

  const clearErrors = () => {
    setValidationErrors({
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
    })
    setErrorMessage(null)
  }

  const startCheckout = async () => {
    clearErrors()

    const validationResult = validateCheckoutForm(formData)

    if (!validationResult.valid) {
      setValidationErrors(validationResult.validationErrors)
      return
    }

    const { firstName, lastName, email, phone } = validationResult.formData!
    const userInfo: UserInput = {
      firstName,
      lastName,
      email,
      phone: { countryCode: phone.countryCode, number: phone.number },
      marketingOptIn: false,
    }
    let userId: string

    shouldShowSpinner(true)

    try {
      if (user) {
        const userResult = await updateUserWithLogin(userInfo)
        userId = userResult.id
      } else {
        const userResult = await createUserWithoutLogin(userInfo)
        userId = userResult.id
      }

      await completeOrder(
        await createOrder({
          userId,
        })
      )
    } catch (error: any) {
      handleCheckoutError({ apolloError: error })
    }
  }

  return (
    <PageLayout>
      <CheckoutLayout
        title={t('title.checkout')}
        onBack={close}
        left={{
          hidden: isMobile,
          title: t('basket.yourBasket'),
          content: (
            <CartItems
              cartItems={cartItems}
              cartTotal={cartTotal}
              creditRedemptions={creditRedemptions}
              membershipBookings={membershipBookings}
              freeTrialRedemption={freeTrialRedemption}
              editable={false}
            />
          ),
        }}
        right={{
          title: t('checkout.yourInformation'),
          content: (
            <>
              <CheckoutForm
                formData={formData}
                validationErrors={validationErrors}
                setFormData={setFormData}
              />

              <div className="text-md mt-4 flex justify-between font-semibold uppercase md:hidden">
                <div>{t('total')}</div>
                <div>{formatMoney(cartTotal, i18n.resolvedLanguage)}</div>
              </div>

              {business && business.bookingOptions.trialPassEnabled && (
                <TrialPassRedemption
                  user={user}
                  cartItems={cartItems}
                  redemptionCode={trialPassRedemptionCode}
                  redemptionCodeChanged={(e) =>
                    setTrialPassRedemptionCode(e.target.value.toUpperCase())
                  }
                />
              )}
              <div className="mt-4">
                <div className="mb-2 mt-10 text-center text-sm">
                  <Trans
                    i18nKey="checkout.terms"
                    components={{
                      agreementText: (
                        <span className="font-medium">
                          {t('button.agreeAndBook')}
                        </span>
                      ),
                      privacyLink: (
                        <Link
                          to="https://wearebookable.com/privacy"
                          target="_blank"
                          rel="noreferrer"
                          className="underline"
                        >
                          {t('privacyPolicy')}
                        </Link>
                      ),
                      termsLink: (
                        <Link
                          to="https://wearebookable.com/terms"
                          target="_blank"
                          rel="noreferrer"
                          className="underline"
                        >
                          {t('termsOfService')}
                        </Link>
                      ),
                    }}
                  />
                </div>

                <button onClick={startCheckout} className="button-primary">
                  {t('button.agreeAndBook')}
                </button>
              </div>

              <div className="mt-4">
                <ErrorMessage
                  visible={errorMessage !== null}
                  message={errorMessage}
                />
              </div>
            </>
          ),
        }}
      />

      <OverlaySpinner
        visible={showSpinner}
        title={t('spinner.titleInProgress', {
          action: t('checkout'),
        })}
        subtitle={t('spinner.subtitlePleaseWait')}
      />
    </PageLayout>
  )
}

export default Checkout
