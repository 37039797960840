import { useMutation } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { LOGIN_PATH } from 'Routes'
import { SignInAppleInput, SignInGoogleInput } from 'generated/graphql'
import { User } from 'generated/graphql'
import { SIGN_IN_APPLE_MUTATION } from 'graphql/SignInApple'
import { SIGN_IN_GOOGLE_MUTATION } from 'graphql/SignInGoogle'
import { AuthProvider } from 'lib/auth'
import { base64UrlDecode } from 'lib/base64Url'
import { useLogin } from 'login/use-login'
import OverlaySpinner from 'shared/components/OverlaySpinner'

const AuthCallback = () => {
  function useQueryString() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
  }

  const { t } = useTranslation()
  const navigate = useNavigate()
  const query = useQueryString()
  const encodedData = query.get('data')
  const data = encodedData ? JSON.parse(base64UrlDecode(encodedData)) : null
  const [signInAppleMutation, { error: appleSignInError }] = useMutation(
    SIGN_IN_APPLE_MUTATION
  )
  const [signInGoogleMutation, { error: googleSignInError }] = useMutation(
    SIGN_IN_GOOGLE_MUTATION
  )
  const { user, setUser } = useLogin()
  const [loginSucceeded, setLoginSucceeded] = useState(false)
  const [loginFailed, setLoginFailed] = useState(false)

  const handleLoginSuccess = (loggedInUser: User) => {
    setUser(loggedInUser)
    setLoginSucceeded(true)
  }

  const handleLoginFailure = (error: any) => {
    console.error(error)
    setLoginFailed(true)
    Sentry.captureException(error)
  }

  useEffect(() => {
    const signInWithApple = async (authorization: SignInAppleInput) => {
      try {
        const result = await signInAppleMutation({
          variables: {
            input: {
              code: authorization.code,
              idToken: authorization.idToken,
            },
          },
        })

        handleLoginSuccess(result.data.signInApple.user)
      } catch (error) {
        handleLoginFailure(error)
      }
    }

    const signInWithGoogle = async (authorization: SignInGoogleInput) => {
      try {
        const result = await signInGoogleMutation({
          variables: {
            input: {
              idToken: authorization.idToken,
            },
          },
        })

        handleLoginSuccess(result.data.signInGoogle?.user)
      } catch (error) {
        handleLoginFailure(error)
      }
    }

    if (data) {
      if (data.success) {
        switch (data.provider) {
          case AuthProvider.Apple:
            signInWithApple({
              code: data.code,
              idToken: data.idToken,
            })
            break
          case AuthProvider.Google:
            signInWithGoogle({ idToken: data.idToken })
            break
          default:
            setLoginFailed(true)
        }
      } else {
        console.error(`${data.provider} sign-in failed.`, data.error)
        setLoginFailed(true)
      }
    } else {
      setLoginFailed(true)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loginSucceeded && user) {
      const path = `/${data.path}`

      navigate(path, {
        replace: true,
        state: path === LOGIN_PATH ? { referrerPath: LOGIN_PATH } : null,
      })
    } else if (loginFailed) {
      navigate(LOGIN_PATH, {
        replace: true,
        state: {
          signInError: true,
          referrerPath: LOGIN_PATH,
        },
      })
    }
  }, [loginSucceeded, loginFailed, user, navigate, data.path])

  if (appleSignInError) {
    console.error('Apple Sign-in Error', appleSignInError)
  }

  if (googleSignInError) {
    console.error('Google Sign-in Error', googleSignInError)
  }

  return (
    <OverlaySpinner
      visible={true}
      title={t('login.loggingIn')}
      subtitle={t('spinner.subtitlePleaseWait')}
    />
  )
}

export default AuthCallback
