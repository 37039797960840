import { gql } from '@apollo/client'

// Queries
export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      ...UserParams
    }
  }
`

// Mutations
export const CREATE_USER_WITHOUT_LOGIN_MUTATION = gql`
  mutation CreateUserWithoutLogin(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: PhoneNumberInput!
    $marketingOptIn: Boolean!
  ) {
    createUserWithoutLogin(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        marketingOptIn: $marketingOptIn
      }
    ) {
      ...UserParams
    }
  }
`

export const UPDATE_USER_WITH_LOGIN_MUTATION = gql`
  mutation UpdateUserWithLogin(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: PhoneNumberInput!
    $marketingOptIn: Boolean!
  ) {
    updateUserWithLogin(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        marketingOptIn: $marketingOptIn
      }
    ) {
      ...UserParams
    }
  }
`
