// Booking
export const ERROR_CANCELLATION_PERIOD_EXPIRED = 'CANCELLATION_PERIOD_EXPIRED'

// Booking Order
export const ERROR_EVENT_OVER_CAPACITY = 'EVENT_OVER_CAPACITY'
export const ERROR_EVENT_CANCELLED = 'EVENT_CANCELLED'
export const RECURRING_EVENT_UNAVAILABLE = 'RECURRING_EVENT_UNAVAILABLE'

// Business
export const ACCOUNT_ID_NOT_FOUND = 'ACCOUNT_ID_NOT_FOUND'

// Credit Order
export const ERROR_CREDIT_BUNDLE_DISCONTINUED = 'CREDIT_BUNDLE_DISCONTINUED'

// Memberships
export const ERROR_MEMBERSHIP_DISCONTINUED = 'MEMBERSHIP_DISCONTINUED'

// Trial Passes
export const ERROR_TRIAL_PASS_ALREADY_REDEEMED = 'TRIAL_PASS_ALREADY_REDEEMED'
export const ERROR_TRIAL_PASS_NOT_ALLOWED = 'TRIAL_PASS_NOT_ALLOWED'
export const ERROR_TRIAL_PASS_REDEMPTION_CODE_INVALID =
  'TRIAL_PASS_REDEMPTION_CODE_INVALID'
export const ERROR_TRIAL_PASS_NOT_ENABLED = 'TRIAL_PASS_NOT_ENABLED'
export const ERROR_TRIAL_PASS_REDEMPTION_RATE_LIMIT =
  'TRIAL_PASS_REDEMPTION_RATE_LIMIT'

// Payment
export const ERROR_PAYMENT_UNAVAILABLE = 'PAYMENT_UNAVAILABLE'
